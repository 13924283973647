import React, {useContext, useState, useEffect} from 'react';
import {Button, Grid, Paper, Box, Typography} from '@mui/material';
import {SummaryContext} from './Interface.js';
import './DefaultStyle.css';

function SuggestedQuestions({handleSampleQuestion}) {
  const {suggestedQuestions} = useContext(SummaryContext);

  return (
    <Box sx={{marginBottom: 2}}>
      <Typography variant="h5" sx={{marginBottom: 1}}>Suggested Questions</Typography>
      <Grid container spacing={1} sx={{padding: 1}}>
        {suggestedQuestions.map((questionObject, index) => (
          <Grid item key={index} xs={12} sm={6}>
            <Button
              variant="contained"
              className="button"
              fullWidth
              onClick={() => handleSampleQuestion(questionObject.question)}
              sx={{marginBottom: 1, textTransform: 'none', fontSize: 'inherit', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}
            >
              <Typography variant="body1" component="div" sx={{fontSize: '0.9em', textAlign: 'left', width: '100%'}}>
                {questionObject.question}
              </Typography>
              {questionObject.similarityScore && (
                <Typography variant="body2" component="div" sx={{fontSize: '0.75em', marginTop: '0.25em', textAlign: 'right', width: '100%'}}>
                  {(questionObject.similarityScore * 100).toFixed(0)}% Similar
                </Typography>
              )}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default SuggestedQuestions;