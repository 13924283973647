import React, {useState} from 'react';
import {IconButton, Tooltip, Snackbar, Modal, Box, Typography, TextField, Button} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import axios from 'axios';

function ResponseFeedback({resultInfo, parentRequestLogId}) {
  const [feedbackVote, setFeedbackVote] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [feedbackId, setFeedbackId] = useState(null);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);

  const submitFeedbackVote = async (vote) => {
    setFeedbackVote(vote);
    const feedbackData = {
      feedback: vote,
      parentRequestLogId: parentRequestLogId,
      resultInfo
    };
    try {
      const response = await axios.post('/api/v1/web/search/resultFeedback', feedbackData, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      setFeedbackId(response.data.results.id);
      setFeedbackSubmitted(false);
      setFeedbackModalOpen(true);
    } catch (error) {
      console.error('Error submitting feedback vote:', error);
    }
  };

  const submitFeedbackComment = async () => {
    try {
      await axios.put(`/api/v1/web/search/resultFeedback`, {
        id: feedbackId,
        comments: feedbackText
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      });
      setShowFeedbackMessage(true);
    } catch (error) {
      console.error('Error submitting feedback comment:', error);
    }
    setFeedbackModalOpen(false);
    setFeedbackText('');
    setShowFeedbackMessage(true);
  };

  const updateFeedbackText = (event) => {
    setFeedbackText(event.target.value);
  };

  const closeModal = () => {
    setFeedbackModalOpen(false);
    setFeedbackText('');
  };

  return (
    <>
      {!feedbackSubmitted && (
        <div>
          <Tooltip title="Helpful Summary" arrow>
            <IconButton onClick={() => submitFeedbackVote(true)} size="24px" style={{color: "#1b5e20"}}>
              <ThumbUpIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Unhelpful Summary" arrow>
            <IconButton onClick={() => submitFeedbackVote(false)} size="24px" style={{color: "#d50000"}}>
              <ThumbDownIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}

      <Modal open={feedbackModalOpen} onClose={closeModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 640, bgcolor: 'background.paper', boxShadow: 24, p: 4, outline: 'none'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Result Feedback
          </Typography>
          <TextField autoFocus fullWidth multiline rows={6} margin="normal" label="Comments" type="text" variant="outlined" value={feedbackText} onChange={updateFeedbackText} />
          <Button onClick={submitFeedbackComment} variant="contained" color="primary" sx={{mt: 2}}>
            Submit
          </Button>
        </Box>
      </Modal>

      <Snackbar open={showFeedbackMessage} autoHideDuration={3000} onClose={() => setShowFeedbackMessage(false)} message={feedbackVote ? "Thank you for your feedback." : "Thank you for your feedback."} />
    </>
  );
}

export default ResponseFeedback;
