import React, {useEffect, useState, useContext} from 'react';
import {SummaryContext} from './Interface.js';

import {Paper, Typography, Button, Select, MenuItem, FormControl, InputLabel, Box, Link} from '@mui/material';

const SourceFilter = () => {
  const {emptyFilters, filters, setFilters, selectedMetadataFilter, setSelectedMetadataFilter, sourceUrl, setSourceUrl, sourceMediaType, setSourceMediaType, isReadyToSubmit, setIsReadyToSubmit} = useContext(SummaryContext);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  const clearFilter = () => {
    setSelectedMetadataFilter({key: '', operator: '', value: ''});
    setFilters(emptyFilters)
    setIsReadyToSubmit(true);
  };

  const toggleImagePopup = () => {
    setIsImagePopupOpen(!isImagePopupOpen);
  };

  const renderYouTubeVideo = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0] || url.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <iframe
        width="640"
        height="360"
        src={embedUrl}
        title="YouTube Video"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    );
  };

  const renderVimeoVideo = (url) => {
    const videoId = url.split('/').pop();
    const embedUrl = `https://player.vimeo.com/video/${videoId}`;
    return (
      <iframe
        width="640"
        height="360"
        src={embedUrl}
        title="Vimeo Video"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen>
      </iframe>
    );
  };

  const renderGenericVideo = (url) => (
    <video width="100%" controls>
      <source src={url} type="video/mp4" />
      Please try again.
    </video>
  );

  const renderContent = () => {
    switch (sourceMediaType) {
      case 'url':
        return <Link href={sourceUrl} target="_blank" rel="noopener noreferrer">{sourceUrl}</Link>;
      case 'image':
        return (
          <Box>
            <img src={sourceUrl} alt="Source" style={{maxHeight: '300px', cursor: 'pointer'}} onClick={() => setIsImagePopupOpen(true)} />
          </Box>
        );
      case 'audio':
        return (
          <audio controls>
            <source src={sourceUrl} type="audio/mpeg" fullwidth />
            Please try again.
          </audio>
        );
      case 'video':
        if (sourceUrl.includes('youtube.com') || sourceUrl.includes('youtu.be')) {
          return renderYouTubeVideo(sourceUrl);
        } else if (sourceUrl.includes('vimeo.com')) {
          return renderVimeoVideo(sourceUrl);
        } else {
          return renderGenericVideo(sourceUrl);
        }
      default:
        return null;
    }
  };

  return (
    <Paper sx={{padding: 2, marginY: 2, borderRadius: 1, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', marginBottom: 2, background: '#eeeeee'}}>
      <Typography variant="h5">
        Source Filter
      </Typography>
      <Typography variant="body1" display="block" gutterBottom>
        <Box component="span" sx={{display: 'block', color: '#444444', marginBottom: 1}}>
          {selectedMetadataFilter.value}
        </Box>
      </Typography>
      <Box sx={{marginTop: 1}}>
        {renderContent()}
      </Box>
      {isImagePopupOpen && (
        <Box onClick={toggleImagePopup} sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src={sourceUrl} alt="Source Image" style={{maxWidth: '90%', maxHeight: '90%'}} />
        </Box>
      )}
      <Box sx={{textAlign: 'right'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={clearFilter}
          sx={{marginTop: 1}}
        >
          Reset
        </Button>
      </Box>
    </Paper>
  );
};

export default SourceFilter;