import React from 'react';
import {Box, Typography, Link} from '@mui/material';
import './DefaultStyle.css'

function Footer() {
  return (
    <Box component="footer" className="footer" sx={{background: '#64aced', position:'fixed',bottom:'0',left:'0',width:'100%',zIndex:'999',padding:'.5em 0',fontFamily:'sans-serif',textTransform: 'none'}}>
      <Box sx={{textAlign: "center"}}>
        <Typography variant="body2">
          &copy;{new Date().getFullYear()} IgniteIQ, Inc. All Rights Reserved.
        </Typography>
        <Link href="https://www.igniteiq.ai/" target="_blank" rel="noopener noreferrer" fontSize="14px" sx={{color: "white"}}>
          https://www.igniteiq.ai/
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;