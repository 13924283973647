import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {FaSpinner} from 'react-icons/fa';
import {SummaryContext} from './Interface.js';

import {FormControl, InputLabel, Select, MenuItem, Box} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  spinner: {
    fontSize: '30px',
    marginLeft: '25px',
    marginTop: '15px',
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  },
});

const SummaryTranslator = () => {
  const {appData, suggestedQuestions, setSuggestedQuestions, summary, setSummary, responseList, setResponseList, personaSummary, setPersonaSummary, reformattedSummary, setReformattedSummary, selectedCorpusId, language, setLanguage, currentLanguage, setCurrentLanguage, setTranslationUpdated, requestLogId} = useContext(SummaryContext);
  const [originalSummary, setOriginalSummary] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [performTranslation, setPerformTranslation] = useState(false);
  const classes = useStyles();

  let languages = [];
  const selectedCorpus = appData.data.results.config.corpora.find(corpus => corpus.corpusId == selectedCorpusId);
  if (selectedCorpusId && selectedCorpus) {
    languages = selectedCorpus.language.options;
  } else {
    languages = [];
  }

  useEffect(() => {
    setIsActive(summary !== '' && language !== '');
  }, [summary, language]);

  useEffect(() => {
    if (performTranslation) {
      handleTranslation();
      setPerformTranslation(false);
    }
  }, [performTranslation]);

  const updateLanguage = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    setPerformTranslation(true);
  };

  const handleTranslation = async () => {
    if (!summary || summary.trim() === '') {
      return null;
    }
    if (language == currentLanguage) {
      return null;
    }
    setIsLoading(true);

    const translateText = async (text) => {
      if (!text) {
        return text;
      }
      try {
        const response = await axios.post(`/api/v1/web/search/translate`, {text: text, language:language, parentRequestLogId: requestLogId});
        return response.data.results.text;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert('A server error occurred. Please try again later.');
        } else {
          alert('An error occurred. Please try again later.');
        }
        return text;
      }
    };

    try {
      const translationPromises = [
        translateText(summary),
        personaSummary ? translateText(personaSummary) : null,
        reformattedSummary ? translateText(reformattedSummary) : null,
        ...responseList.map(item => translateText(item.text).then(translatedText => ({ ...item, text: translatedText }))),
        ...suggestedQuestions.map(item => translateText(item.question).then(translatedText => ({ ...item, question: translatedText })))
      ];
      const translatedContent = await Promise.all(translationPromises);
      setSummary(translatedContent[0]);
      if (personaSummary) {
        setPersonaSummary(translatedContent[1]);
      }
      if (reformattedSummary) {
        setReformattedSummary(translatedContent[2]);
      }
      setResponseList(translatedContent.slice(3, 3 + responseList.length));
      setSuggestedQuestions(translatedContent.slice(3 + responseList.length));
    } catch (error) {
      console.error('Error:', error);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    } finally {
      setIsLoading(false);
      setTranslationUpdated(true);
    }
  }

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 1, width: '100%'}}>
      <FormControl fullWidth>
        <InputLabel>Summary Language</InputLabel>
        {isLoading ? (
          <FaSpinner className={classes.spinner} />
        ) : (
          <Select value={language} label="Summary Language" onChange={updateLanguage}>
            <MenuItem value='' disabled></MenuItem>
            {languages.map(lang => (
              <MenuItem key={lang} value={lang}>{lang}</MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

export default SummaryTranslator;

export const translateSummary = async (text, targetLanguage, requestLogId) => {
  try {
    const response = await axios.post(`/api/v1/web/search/translate`, {text: text, language:targetLanguage, parentRequestLogId: requestLogId});
    return response.data.results.text;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      alert('A server error occurred. Please try again later.');
    } else {
      alert('An error occurred. Please try again later.');
    }
    return text;
  }
};