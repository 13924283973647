import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { FallbackComponent } from "./FallbackComponent";

export const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </ReactErrorBoundary>
  );
};