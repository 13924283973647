import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./VectaraChatbot.css";
import AssistantIcon from '@mui/icons-material/Assistant';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

function VectaraChatbot() {
  const textAreaRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const [isStreamingResponse, setIsStreamingResponse] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [activeMessage, setActiveMessage] = useState({});
  
  const VECTARA_API_KEY = process.env.REACT_APP_VECTARA_API_KEY;
  const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;
  const CORPUS_ID = process.env.REACT_APP_CORPUS_ID;

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const sendBtnHandler = async () => {
    const userMessage = textAreaRef.current.value.trim();
    if (!userMessage) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { message: userMessage, className: "outgoing" },
    ]);
    textAreaRef.current.value = "";
    setIsLoading(true);

    try {
      const response = await axios.post('https://api.vectara.io/v1/', {
        customer_id: CUSTOMER_ID,
        corpus_ids: [CORPUS_ID],
        api_key: VECTARA_API_KEY,
        enable_streaming: true,
        language: "fra",
        message: userMessage,
      });

      const botMessage = response.data.message;
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: botMessage, className: "incoming" },
      ]);
      setActiveMessage({ text: botMessage });

      if (response.data.enable_streaming) {
        setIsStreamingResponse(true);
        const streamInterval = setInterval(() => {
          setActiveMessage((prev) => ({ text: prev.text + '.' }));
        }, 500);

        setTimeout(() => {
          clearInterval(streamInterval);
          setIsStreamingResponse(false);
        }, 3000);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setHasError(true);
      setMessages((prevMessages) => [
        ...prevMessages,
        { message: "Failed to get response. Please try again.", className: "incoming" },
      ]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChatbot = () => {
    setIsChatbotVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className={`chatbot-container ${isChatbotVisible ? 'show-chatbot' : ''}`}>
      <button className="chatbot-toggler" onClick={toggleChatbot}>
        <span>{<AssistantIcon/>}</span>
        <span>{<CloseIcon/>}</span>
      </button>

      {isChatbotVisible && (
        <div className="chatbot">
          <header>
            <h2>ChatBot</h2>
            <span onClick={toggleChatbot}>C</span>
          </header>
          <ul className="chatbox" ref={chatBoxRef}>
            <li className="chat incoming">
              <span>{<AssistantIcon/>}</span>
              <p>
                Hello there 👋 <br /> How can I help you today?
              </p>
            </li>
            {messages.map((msg, index) => (
              <li key={index} className={`chat ${msg.className}`}>
                <span>{msg.className === "incoming" ? <AssistantIcon/> : ""}</span>
                <p>{msg.message}</p>
              </li>
            ))}
          </ul>
          <div className="chat-input">
            <textarea
              ref={textAreaRef}
              placeholder="Enter a message..."
              required
              disabled={isLoading}
            ></textarea>
            <span onClick={sendBtnHandler} disabled={isLoading}>{<SendIcon/>}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default VectaraChatbot;
