import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Menu,
  Grid,
  MenuItem,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/material/styles";
import { ThemeContext } from "./index";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { FaSpinner } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const StyledTab = styled(Tab)({
  color: "#bababa",
  "&.Mui-selected": {
    color: "#f8f8f8",
  },
});

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#424242",
  "& .MuiTableCell-root": {
    color: "#ffffff",
    fontFamily: "sans-serif",
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
// dialogBox
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Administration({ subscriptionStartDate }) {
  const [value, setValue] = useState(0);
  const [usageData, setUsageData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [showMUTable, setMUbutton] = useState(false);
  const [showMUUTable, setMUUbutton] = useState(false);
  const [showFeedback, setFeedbackButton] = useState(false);
  const [showFBbyUser, setFBbyUserButton] = useState(false);
  const [showResultFeedback, setRFButton] = useState(false);
  const [showRFbyUser, setRFbyUserButton] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [searched, setSearched] = useState("");
  const [configText, setConfigText] = useState("");
  const [filterTagsText, setFilterTagsText] = useState("");
  const [isValidJson, setIsValidJson] = useState(false);
  const [isValidFTagsJson, setIsValidFTagsJson] = useState(false);
  const [enteredName, setenteredName] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState(null);
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [activeFeedbackItem, setActiveFeedbackItem] = useState(null);
  //
  const [configData, setConfigData] = useState([]);
  const [configTagsData, setConfigTagsData] = useState({});
  const [filterTagsData, setFilterTagsData] = useState({});
  const [enteredNameData, setEnteredNameData] = useState("");
  const [configDescription, setConfigDescription] = useState("");
  const [selectedData, setSelectedData] = React.useState(null);

  // dialogBox
  const [openDialog, setOpenDialog] = React.useState(false);

  const [showRebuildFilterTagsConfirmation, setShowRebuildFilterTagsConfirmation] = useState(false);
  const [isRebuildingFilterTags, setIsRebuildingFilterTags] = useState(false);
  const [exportStartDate, setExportStartDate] = useState("");
  const [exportEndDate, setExportEndDate] = useState("");
  const [exportPeriod, setExportPeriod] = useState("Month");

  const classes = useStyles();

  const handleDialogOpen = (data) => {
    setSelectedData(data);
    setConfigText(JSON.stringify(data.configTags, null, 2));
    setEnteredNameData(data.name);
    setConfigDescription(data.description);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    Transition: Fade,
  });

  const handleSnackBarClick = (Transition) => {
    console.log("Opening snackbar with transition:", Transition);
    setSnackBarState({
      open: true,
      Transition,
    });
  };

  const handleSnackBarClose = () => {
    setSnackBarState({
      ...snackBarState,
      open: false,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const feedbackAnchorHandler = (event) => {
    setFeedbackAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFeedbackAnchorEl(null);
  };

  // //  Incase we need to open a menu onHover
  // const openMonthlyUsageMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const closeMonthlyUsageMenu = () => {
  //   setAnchorEl(null);
  // };
  // const openUserFeedbackMenu = (event) => {
  //   setFeedbackAnchorEl(event.currentTarget);
  // };

  // const closeUserFeedbackMenu = () => {
  //   setFeedbackAnchorEl(null);
  // };

  const handleConfigJsonChange = (event) => {
    const input = event.target.value;
    setConfigText(input);
    try {
      JSON.parse(input);
      setIsValidJson(true);
    } catch (error) {
      setIsValidJson(false);
    }
  };

  const handleFilterTagsJsonChange = (event) => {
    const input = event.target.value;
    setFilterTagsText(input);
    try {
      JSON.parse(input);
      setIsValidFTagsJson(true);
    } catch (error) {
      setIsValidFTagsJson(false);
    }
  };

  const handleNameChange = (event) => {
    setEnteredNameData(event.target.value);
  };

  const configDescChange = (event) => {
    setConfigDescription(event.target.value);
  };

  const StyledTableSortLabel = styled(TableSortLabel)({
    "&.MuiTableSortLabel-root": {
      color: "#ffffff",
    },
    "&.MuiTableSortLabel-active": {
      color: "#ffffff",
    },
    "&.MuiTableSortLabel-icon": {
      color: "#ffffff !important",
    },
  });

  let formattedDate = "";

  if (subscriptionStartDate.trim() !== "") {
    formattedDate = new Date(subscriptionStartDate)
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/(\w+)\s(\d+),\s(\d+)/, "$1-$2-$3");
  }

  const calculateEndDate = (startDate) => {
    if (!startDate || startDate.trim() === "") {
      return "";
    }

    const [month, day, year] = startDate.split("-");
    const monthIndex = new Date(`${month} 1, 2000`).getMonth();
    const startDateObj = new Date(year, monthIndex, day);
    startDateObj.setDate(startDateObj.getDate() + 30);
    // startDateObj.setMonth(startDateObj.getMonth() + 1);
    const endDate = startDateObj
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      })
      .replace(/(\w+)\s(\d+),\s(\d+)/, "$1-$2-$3");

    return endDate;
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const monthlyHandleClick = (index) => {
    setActiveMenuItem(index);
    if (index === 0) {
      setMUbutton(true);
      setMUUbutton(false);
    } else if (index !== 0) {
      setMUbutton(false);
      setMUUbutton(true);
    }
  };
  const feedbackHandleClick = (index) => {
    setActiveFeedbackItem(index);
    setFeedbackButton(index === 0);
    setFBbyUserButton(index === 1);
    setRFButton(index === 2);
    setRFbyUserButton(index === 3);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses = await Promise.all([
          axios.get("/api/v1/web/admin/data/usage/queriesByMonth"),
          axios.get("/api/v1/web/admin/data/usage/queriesByMonthByUser"),
          axios.get("/api/v1/web/admin/data/feedback/byMonth"),
          axios.get("/api/v1/web/admin/data/feedback/byMonthByUser"),
          axios.get("/api/v1/web/admin/data/resultFeedback/byMonth"),
          axios.get("/api/v1/web/admin/data/resultFeedback/byMonthByUser"),
          axios.get("/api/v1/web/app/"),
        ]);
        setUsageData([responses[0].data.results, responses[1].data.results]);
        setFeedbackData([
          responses[2].data.results,
          responses[3].data.results,
          responses[4].data.results,
          responses[5].data.results,
        ]);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  // config editor
  const getJson = async () => {
    try {
      const response = await axios.get("/api/v1/web/app");
      const data = response.data.results;
      console.log("Fetched Data:", data);
      setConfigTagsData(data.config || {});
      setFilterTagsData(data.filterTags || {});
      setEnteredNameData(data.name || "");
      setConfigDescription(data.description || "");
      setConfigData([
        {
          name: data.name,
          configTags: data.config,
          filterTags: data.filterTags,
          description: data.description,
        },
      ]);
    } catch (error) {
      console.error("Error Fetching JSON for config: ", error);
    }
  };
  useEffect(() => {
    getJson();
  }, []);

  useEffect(() => {
    console.log("configTagsData", configTagsData);
    console.log("filterTagsData", filterTagsData);
    console.log("enteredNameData", enteredNameData);
    console.log("configDescription", configDescription);
  }, [configTagsData, filterTagsData, enteredNameData, configDescription]);

  const updateJson = async () => {
    try {
      const response = await axios.put("/api/v1/web/admin/app", {
        config: JSON.parse(configText),
        // filterTags: JSON.parse(filterTagsText), // Uncomment if filterTags are used
        name: enteredNameData,
        description: configDescription,
      });
      console.log("Response:", response.data);
      setIsSubmitted(true);
      handleSnackBarClick(SlideTransition);
      setTimeout(() => {
        setIsSubmitted(false);
        setOpenDialog(false); // Close the dialog after successful submission
      }, 2000);
    } catch (error) {
      console.error("Error updating JSON:", error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openRebuildFilterTagsConfirmation = () => {
    setShowRebuildFilterTagsConfirmation(true);
  };

  const closeRebuildFilterTagsConfirmation = () => {
    setShowRebuildFilterTagsConfirmation(false);
  };

  const rebuildFilterTags = async () => {
    setIsRebuildingFilterTags(true);
    try {
      const response = await axios.put("/api/v1/web/admin/app/updateFilterTags");
    } catch (error) {
      console.error(error);
    } finally {
      setIsRebuildingFilterTags(false);
    }
  };

  const fetchExportData = (endpoint, filename) => {
    axios.get(endpoint)
      .then(response => {
        console.log(response.data);
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch(error => {
        console.error(error);
      });
  };

  const exportData = (endpoint, startDate, endDate, period) => {
    let updatedEndpoint = `${endpoint}?`;
    if (period && endpoint.includes('summaryExport')) {
      updatedEndpoint += `&where[period]=${period}`;
    }
    if (startDate) {
      updatedEndpoint += `&where[startDate]=${startDate}`;
    }
    if (endDate) {
      updatedEndpoint += `&where[endDate]=${endDate}`;
    }
    let filename;
    switch (endpoint) {
      case '/api/v1/web/admin/data/usage/summaryExport':
        filename = 'summary_export.csv';
        break;
      case '/api/v1/web/admin/data/usage/queryDetail':
        filename = 'query_detail.csv';
        break;
      case '/api/v1/web/admin/data/feedback/detail':
        filename = 'feedback_detail.csv';
        break;
      case '/api/v1/web/admin/data/resultFeedback/detail':
        filename = 'result_feedback_detail.csv';
        break;
      default:
        filename = 'export.csv';
        break;
    }
    fetchExportData(updatedEndpoint, filename);
  };

  const theme = useTheme();
  const { isDarkMode } = useContext(ThemeContext);
  const renderTable = (
    rowData,
    formattedDate,
    includeDates,
    searchPlaceholder,
    tableName
  ) => (
    <Paper margin="0" sx={{ marginTop: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <TextField
          variant="outlined"
          placeholder={searchPlaceholder}
          value={searched}
          onChange={(e) => setSearched(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "5px",
            },
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                sx={{
                  color: isDarkMode ? "#B3B3B3" : theme.palette.text.primary,
                  mr: 1,
                  my: 0.5,
                }}
              />
            ),
          }}
        />
        <CSVLink
          data={rowData.map((row) => ({
            startDate: formattedDate,
            endDate: calculateEndDate(formattedDate),
            ...row,
          }))}
          filename={`${tableName}`}
          style={{
            textDecoration: "none",
            backgroundColor: "grey",
            color: "white",
            borderRadius: "5px",
            padding: "5px",
            textAlign: "left",
            minWidth: "100px",
            display: "inline-block",
          }}
          headers={[
//            { label: "Start Date", key: "startDate" },
//            { label: "End Date", key: "endDate" },
            ...Object.keys(rowData[0] || {}).map((key) => ({
              label: key,
              key: key,
            })),
          ]}
        >
          Download CSV
        </CSVLink>
      </div>

      <Table sx={{}} aria-label="simple table">
        <StyledTableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                paddingTop: "12px",
                paddingBottom: "12px",
                textAlign: "left",
              },
            }}
          >
            {/*
            {includeDates && <TableCell>Start Date</TableCell>}
            {includeDates && <TableCell>End Date</TableCell>}
            */}
            {Object.keys(rowData[0] || {}).map((key, cellIndex) => (
              <TableCell
                key={cellIndex}
                sortDirection={orderBy === key ? order : false}
              >
                <StyledTableSortLabel
                  active={orderBy === key}
                  direction={orderBy === key ? order : "asc"}
                  onClick={() => handleSort(key)}
                >
                  {key}
                </StyledTableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {stableSort(rowData, getComparator(order, orderBy))
            .filter((row) => {
              const searchLower = searched.toLowerCase();
              return Object.values(row).some((value) => {
                const valueStr = (value !== null && value !== undefined) ? value.toString().toLowerCase() : "null";
                return (
                  valueStr.includes(searchLower) ||
                  (/\b[A-Za-z]{3}-\d{2}-\d{4}\b/.test(valueStr) &&
                    valueStr.includes(searchLower))
                );
              });
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                sx={{
                  backgroundColor: rowIndex % 2 === 0 ? "white" : "lightgrey",
                  "& .MuiTableCell-root": {
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  },
                }}
              >
                {/*
                {includeDates && <TableCell>{formattedDate}</TableCell>}
                {includeDates && (
                  <TableCell>{calculateEndDate(formattedDate)}</TableCell>
                )}
                */}
                {Object.values(row).map((value, cellIndex) => (
                  <TableCell key={cellIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          "& .MuiTableCell-root": {
            paddingTop: "10px",
            paddingBottom: "10px",
          },
        }}
      />
    </Paper>
  );

  return (
    <Box
      sx={{
        width: "100%",
        padding: "0em 0.5em",
        height: "80vh",
        overflowY: "hidden",
        "&.scrollable": {
          overflowY: "auto",
        },
        "&::-webkit-scrollbar": {
          width: "0 !important",
        },
      }}
    >
      <AppBar
        position="static"
        sx={{
          flexWrap: "wrap",
          justifyContent: "center",
          backgroundColor: "#2222aa",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="none"
          allowScrollButtonsMobile
        >
          <StyledTab label="Subscription" />
          <StyledTab label="Monthly Usage" onClick={handleClick} />
          <Menu
            id="monthlyUsage_Menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                monthlyHandleClick(0);
              }}
              selected={activeMenuItem === 0}
            >
              Monthly Usage
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                monthlyHandleClick(1);
              }}
              selected={activeMenuItem === 1}
            >
              Monthly Usage By User
            </MenuItem>
          </Menu>

          <StyledTab label="User Feedback" onClick={feedbackAnchorHandler} />
          <Menu
            id="feedback_Menu"
            anchorEl={feedbackAnchorEl}
            keepMounted
            open={Boolean(feedbackAnchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                feedbackHandleClick(0);
              }}
              selected={activeFeedbackItem === 0}
            >
              Feedback
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                feedbackHandleClick(1);
              }}
              selected={activeFeedbackItem === 1}
            >
              Feedback By User
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                feedbackHandleClick(2);
              }}
              selected={activeFeedbackItem === 2}
            >
              Results Feedback
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                feedbackHandleClick(3);
              }}
              selected={activeFeedbackItem === 3}
            >
              Results Feedback By User
            </MenuItem>
          </Menu>

          <StyledTab label="Export" />

        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <Typography variant="h6" sx={{ mt: 1, textAlign: "left" }}>
          Subscription Start Date: {formattedDate}
        </Typography>
        <Button
          className="button"
          variant="contained"
          color="primary"
          onClick={openRebuildFilterTagsConfirmation}
          disabled={isRebuildingFilterTags}
          sx={{mt: 2}}
        >
          {isRebuildingFilterTags ? (
            <FaSpinner className={classes.spinner} size={24} />
          ) : (
            "Rebuild Filters"
          )}
        </Button>
      </TabPanel>

      <Dialog
        open={showRebuildFilterTagsConfirmation}
        onClose={closeRebuildFilterTagsConfirmation}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Rebuild Filters
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Please confirm or cancel a rebuild of all filters.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              rebuildFilterTags(); closeRebuildFilterTagsConfirmation();
            }}
            color="primary"
            sx={{backgroundColor: 'green', color: 'white', '&:hover': {backgroundColor: 'darkgreen'}}}
            autoFocus
          >
            Confirm
          </Button>
          <Button
            onClick={closeRebuildFilterTagsConfirmation}
            color="primary"
            sx={{backgroundColor: 'red', color: 'white', '&:hover': {backgroundColor: 'darkred'}}}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TabPanel value={value} index={1}>
        {usageData.map((tableData, index) => (
          <React.Fragment key={index}>
            {index === 0
              ? showMUTable && (
                  <div>
                    {tableData.length > 0 ? (
                      renderTable(
                        tableData,
                        formattedDate,
                        true,
                        "Search by Month",
                        "Monthly_usage"
                      )
                    ) : (
                      <Typography>No data available.</Typography>
                    )}
                  </div>
                )
              : showMUUTable && (
                  <div>
                    {tableData.length > 0 ? (
                      renderTable(
                        tableData,
                        formattedDate,
                        true,
                        "Search by UserId / Month",
                        "Monthly_usage_by_user"
                      )
                    ) : (
                      <Typography>No data available.</Typography>
                    )}
                  </div>
                )}
          </React.Fragment>
        ))}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {feedbackData.map((tableData, index) => (
          <React.Fragment key={index}>
            {index === 0 && showFeedback && (
              <div>
                {tableData.length > 0 ? (
                  renderTable(
                    tableData,
                    "",
                    false,
                    "Search by Month",
                    "Feedback"
                  )
                ) : (
                  <Typography>No data available.</Typography>
                )}
              </div>
            )}
            {index === 1 && showFBbyUser && (
              <div>
                {tableData.length > 0 ? (
                  renderTable(
                    tableData,
                    "",
                    false,
                    "Search by UserId / Month",
                    "Feedback_by_user"
                  )
                ) : (
                  <Typography>No data available.</Typography>
                )}
              </div>
            )}
            {index === 2 && showResultFeedback && (
              <div>
                {tableData.length > 0 ? (
                  renderTable(
                    tableData,
                    "",
                    false,
                    "Search by Month",
                    "Results_feedback"
                  )
                ) : (
                  <Typography>No data available</Typography>
                )}
              </div>
            )}
            {index === 3 && showRFbyUser && (
              <div>
                {tableData.length > 0 ? (
                  renderTable(
                    tableData,
                    "",
                    false,
                    "Search by UserId / Month",
                    "Results_feedback_by_user"
                  )
                ) : (
                  <Typography>No data available</Typography>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {/*  */}

        <Paper sx={{ width: "100%", overflow: "hidden", margin: "10px 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Search by Name / Desc"
              value={searched}
              onChange={(e) => setSearched(e.target.value)}
              sx={{
                "& .MuiOutlinedInput-input": {
                  padding: "5px",
                },
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    sx={{
                      color: isDarkMode
                        ? "#B3B3B3"
                        : theme.palette.text.primary,
                      mr: 1,
                      my: 0.5,
                    }}
                  />
                ),
              }}
            />
            <CSVLink
              data={configData.map((row) => ({
                ...row,
              }))}
              filename="Config Editor"
              style={{
                textDecoration: "none",
                backgroundColor: "grey",
                color: "white",
                borderRadius: "5px",
                padding: "5px",
                textAlign: "left",
                minWidth: "100px",
                display: "inline-block",
              }}
              headers={[
                ...Object.keys(configData[0] || {}).map((key) => ({
                  label: key,
                  key: key,
                })),
              ]}
            >
              Download CSV
            </CSVLink>
          </div>
          <TableContainer sx={{ height: 450 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-root": {
                      backgroundColor: "#424242",
                      color: "#fff",
                      paddingTop: "12px",
                      paddingBottom: "12px",
                      textAlign: "left",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      fontSize: "18px",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Configs</TableCell>
                  <TableCell>Filter Tags</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(configData, getComparator(order, orderBy))
                  .filter((row) => {
                    const searchLower = searched.toLowerCase();
                    return Object.values(row).some((value) => {
                      const valueStr = (value !== null && value !== undefined) ? value.toString().toLowerCase() : "null";
                      return valueStr.includes(searchLower);
                    });
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        "& .MuiTableCell-root": {
                          backgroundColor:
                            rowIndex % 2 === 0 ? "white" : "lightgrey",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                        },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="left">
                        {Object.entries(row.configTags || {}).length > 0
                          ? Object.entries(row.configTags).map(
                              ([key, value]) => (
                                <div key={key}>{`${key}: ${value}`}</div>
                              )
                            )
                          : "No Config Tags"}
                      </TableCell>
                      <TableCell align="left">
                        {Object.entries(row.filterTags || {}).length > 0
                          ? Object.entries(row.filterTags).map(
                              ([key, value]) => (
                                <div key={key}>{`${key}: ${value}`}</div>
                              )
                            )
                          : "No Filter Tags"}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          onClick={() => handleDialogOpen(row)}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={configData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              "& .MuiTableCell-root": {
                paddingTop: "10px",
                paddingBottom: "10px",
              },
            }}
          />
        </Paper>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleDialogClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Config Editor
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                updateJson();
              }}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ padding: "3em" }}
              >
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={15}
                    fullWidth
                    variant="outlined"
                    placeholder="Enter Configs"
                    value={configText}
                    onChange={handleConfigJsonChange}
                    error={!isValidJson}
                    // helperText={!isValidJson ? "Input must be valid JSON." : ""}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                <TextField
                  multiline
                  fullWidth
                  rows={20}
                  variant="outlined"
                  placeholder="Enter Filter Tags"
                  value={filterTagsText}
                  onChange={handleFilterTagsJsonChange}
                  error={!isValidFTagsJson}
                  disabled
                  helperText={
                    !isValidFTagsJson ? "Input must be valid JSON." : ""
                  }
                />
              </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Enter the name"
                    value={enteredNameData}
                    fullWidth
                    variant="outlined"
                    onChange={handleNameChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    label="Enter Description"
                    value={configDescription}
                    fullWidth
                    variant="outlined"
                    onChange={configDescChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{ padding: "1em 2em" }}
                    fullWidth
                    variant="outlined"
                    type="submit"
                    onClick={updateJson}
                    disabled={!isValidJson || isSubmitted}
                  >
                    {isSubmitted ? "Submitted" : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </form>

            <Snackbar
              open={snackBarState.open}
              onClose={handleSnackBarClose}
              TransitionComponent={snackBarState.Transition}
              message="Submitted Successfully"
              key={snackBarState.Transition.name}
              autoHideDuration={2000}
            />
          </Box>
        </Dialog>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <Box sx={{mt: 2, textAlign: "center"}}>
          <TextField
            label="Start Date"
            type="date"
            value={exportStartDate}
            onChange={(e) => setExportStartDate(e.target.value)}
            sx={{m: 1, width: "200px"}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="End Date"
            type="date"
            value={exportEndDate}
            onChange={(e) => setExportEndDate(e.target.value)}
            sx={{m: 1, width: "200px"}}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Tooltip title="Tne export period is only applied to summary exports." placement="top">
            <FormControl sx={{m: 1, width: "200px"}}>
              <InputLabel id="export-period-label">
                Export Period
              </InputLabel>
              <Select
                labelId="export-period-label"
                id="export-period"
                value={exportPeriod}
                onChange={(e) => setExportPeriod(e.target.value)}
                label="Export Period"
              >
                <MenuItem value="Day">
                  Daily
                </MenuItem>
                <MenuItem value="Week">
                  Weekly
                </MenuItem>
                <MenuItem value="Month">
                  Monthly
                </MenuItem>
                <MenuItem value="Year">
                  Yearly
                </MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Box>

        <Box sx={{mt: 2, textAlign: "center"}}>
          <Button
            variant="contained"
            color="primary"
            sx={{m: 1}}
            onClick={() => exportData('/api/v1/web/admin/data/usage/summaryExport', exportStartDate, exportEndDate, exportPeriod)}
          >
            Export Summary
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{m: 1}}
            onClick={() => exportData('/api/v1/web/admin/data/usage/queryDetail', exportStartDate, exportEndDate)}
          >
            Export Query Detail
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{m: 1}}
            onClick={() => exportData('/api/v1/web/admin/data/feedback/detail', exportStartDate, exportEndDate)}
          >
            Export Feedback Detail
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{m: 1}}
            onClick={() => exportData('/api/v1/web/admin/data/resultFeedback/detail', exportStartDate, exportEndDate)}
          >
            Export Result Feedback Detail
          </Button>
        </Box>
      </TabPanel>
    </Box>
  );
}

export default Administration;
