import React, {useEffect, useContext, useState} from 'react';
import {SummaryContext} from './Interface.js';
import {TextField, Select, MenuItem, FormControl, InputLabel, Checkbox, OutlinedInput, ListItemText, Tooltip, IconButton, Box, InputAdornment} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';

const FilterTag = ({filterTagOption}) => {
  const {filters, setFilters, clearFiltersTrigger} = useContext(SummaryContext);
  const emptyValue = filterTagOption.inputControl === 'multiSelectDropdown' ? [] : '';
  const [value, setValue] = useState(emptyValue);
  const [displayValue, setDisplayValue] = useState(emptyValue);

  useEffect(() => {
    setValue(emptyValue);
    setDisplayValue(emptyValue);
  }, [clearFiltersTrigger]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setDisplayValue(newValue);
    const newFilters = filters.map(filter =>
      filter[0] === filterTagOption.tag ? [filterTagOption.tag, filterTagOption.operator, newValue] : filter
    );
    setFilters(newFilters);
  };

  const handleMultiSelectChange = (event) => {
    const {value: newValue} = event.target;
    const formattedVal = Array.isArray(newValue) ? `('${newValue.join("', '")}')` : '';
    setValue(newValue);
    setDisplayValue(newValue);
    const newFilters = filters.map(filter =>
      filter[0] === filterTagOption.tag ? [filterTagOption.tag, filterTagOption.operator, formattedVal] : filter
    );
    setFilters(newFilters);
  };

  const clearSelection = () => {
    setValue('');
    setDisplayValue('');
    const newFilters = filters.map(filter =>
      filter[0] === filterTagOption.tag ? [filterTagOption.tag, filterTagOption.operator, ''] : filter
    );
    setFilters(newFilters);
  };

  let inputElement;
  switch (filterTagOption.inputControl) {
    case 'textbox':
    case 'singleEntry':
      inputElement = <TextField label={filterTagOption.title} value={value} onChange={handleChange} fullWidth />;
      break;
    case 'singleSelectDropdown':
      inputElement = filterTagOption.options.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel>
            {filterTagOption.title}
          </InputLabel>
          <Select
            value={value}
            label={filterTagOption.title}
            onChange={handleChange}
            endAdornment={
              value ? (
                <InputAdornment position="end">
                  <IconButton className="icon" onClick={clearSelection} size="small" sx={{marginRight: 1}}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
          >
            {filterTagOption.options.map((val, index) => (
              <MenuItem key={index} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null;
      break;
    case 'multiSelectDropdown':
      inputElement = filterTagOption.options.length > 0 ? (
        <FormControl fullWidth>
          <InputLabel>
            {filterTagOption.title}
          </InputLabel>
          <Select
            multiple
            value={displayValue}
            onChange={handleMultiSelectChange}
            input={<OutlinedInput label={filterTagOption.title} />}
            renderValue={(selected) => selected.join(', ')}
          >
            {filterTagOption.options.map((val) => (
              <MenuItem key={val} value={val}>
                <Checkbox checked={displayValue.indexOf(val) > -1} />
                <ListItemText primary={val} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null;
      break;
    case 'dateSelector':
      inputElement = <TextField
        label={filterTagOption.title}
        type="date"
        value={value}
        onChange={handleChange}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />;
      break;
    case 'doubleEntry':
      inputElement = (
        <div>
          <TextField
            label={`${filterTagOption.title} (From)`}
            type="text"
            value={Array.isArray(value) ? value[0] : ''}
            onChange={(e) => handleChange({target: {value: [e.target.value, value[1]]}})}
            fullWidth
          />
          <TextField
            label={`${filterTagOption.title} (To)`}
            type="text"
            value={Array.isArray(value) ? value[1] : ''}
            onChange={(e) => handleChange({target: {value: [value[0], e.target.value]}})}
            fullWidth
            style={{marginTop: '10px'}}
          />
        </div>
      );
      break;
    default:
      inputElement = <div>Unsupported control type.</div>;
  }

  return (
    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
      <FormControl fullWidth variant="outlined" sx={{display: 'flex', flexDirection: 'column'}}>
        {inputElement}
      </FormControl>
      {filterTagOption.description && (
        <Tooltip title={filterTagOption.description}>
          <IconButton className="icon" size="large">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default FilterTag;