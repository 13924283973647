import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const FallbackComponent = ({ error, resetErrorBoundary }) => {
  const isDevelopment = process.env.NODE_ENV === 'development';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
      p={2}
    >
      <Typography variant="h4" gutterBottom>
        Something went wrong.
      </Typography>
      <Typography variant="body1" gutterBottom>
        {isDevelopment ? error.message : 'An error occurred'}
      </Typography>
      <Button variant="contained" color="primary" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </Box>
  );
};

export { FallbackComponent };
