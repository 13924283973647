import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {FaSpinner} from 'react-icons/fa';
import {SummaryContext} from './Interface.js';
import {Box, TextField, Button, IconButton, InputAdornment, Tooltip} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import RefreshIcon from '@mui/icons-material/Refresh';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

const useStyles = makeStyles({
  spinner: {
    fontSize: '30px',
    marginLeft: '15px',
    marginTop: '15px',
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  },
});

const SummaryFormatter = () => {
  const {summary, language, formatInstructions, setFormatInstructions, reformattedSummary, setReformattedSummary, requestLogId} = useContext(SummaryContext);
  const [originalSummary, setOriginalSummary] = useState('');
  const [sampleInstructionIndex, setSampleInstructionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (submitTrigger) {
      setSubmitTrigger(false);
      handleReformat();
    }
  }, [submitTrigger]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitTrigger(prev => !prev);
    }
  }

  const handleReformat = async () => {
    if (formatInstructions.trim() === '') {
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`api/v1/web/search/reformat`, {summary: summary, instructions: formatInstructions, language: language, parentRequestLogId: requestLogId});

      try {
        const prevSummary = summary;
        setReformattedSummary(response.data.results.text);
        setOriginalSummary(prevSummary)
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert('A server error occurred. Please try again later.');
        } else {
          alert('An error occurred. Please try again later.');
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleReset = () => {
    setFormatInstructions('');
    setReformattedSummary('');
  };

  const sampleInstructions = [
    "Rewrite as a list.",
    "Use bullet points to convey information.",
    "Rephrase using formal language.",
    "Rephrase using casual terminology.",
    "Extract crucial information.",
    "Add humour.",
  ]

  const loadSampleInstructions = () => {
    setSampleInstructionIndex(sampleInstructionIndex + (Math.floor(Math.random() * (sampleInstructions.length - 1))) + 1);
    setFormatInstructions(sampleInstructions[sampleInstructionIndex % sampleInstructions.length]);
  }

  return (
    <Box sx={{display: 'flex', width: '100%'}}>
      <TextField
        fullWidth
        label="Reformat Summary"
        helperText="Summary reformatting instructions. Enter to submit."
        variant="outlined"
        value={formatInstructions}
        onChange={(e) => setFormatInstructions(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={isLoading}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {reformattedSummary && (
                <IconButton className="icon" onClick={handleReset} size="small">
                  <ClearIcon />
                </IconButton>
              )}
              <Tooltip title="Load sample description.">
                <TipsAndUpdatesIcon onClick={loadSampleInstructions} style={{cursor: 'pointer', color: 'gold'}} />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      {isLoading && (
        <FaSpinner className={classes.spinner} />
      )}
    </Box>
  );
};

export default SummaryFormatter;

export const reformatSummary = async (summary, formatInstructions, language, requestLogId) => {
  try {
    const response = await axios.post(`api/v1/web/search/reformat`, {summary: summary, instructions: formatInstructions, language: language, parentRequestLogId: requestLogId});

    try {
      return response.data.results.text;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert('A server error occurred. Please try again later.');
      } else {
        alert('An error occurred. Please try again later.');
      }
      return summary;
    }
  } catch (error) {
    console.error('Error:', error);
    return summary;
  }
};