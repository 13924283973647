import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {SummaryContext} from "./Interface.js";
import {FormControl, InputLabel, Select, MenuItem, Box, IconButton, InputAdornment} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Stack from "@mui/material/Stack";
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles({
  spinner: {
    fontSize: "30px",
    marginLeft: "25px",
    marginTop: "15px",
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  },
});

const SummaryPersona = () => {
  const {appData, selectedCorpusId, summary, language, persona, setPersona, personaSummary, setPersonaSummary, requestLogId} = useContext(SummaryContext);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [personaList, setPersonaList] = useState([]);
  const [performPersonaRewrite, setPerformPersonaRewrite] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const selectedCorpus = appData.data.results.config.corpora.find(
      (corpus) => corpus.corpusId == selectedCorpusId
    );
    if (selectedCorpusId && selectedCorpus) {
      setPersonaList(selectedCorpus.persona.options);
    } else {
      setPersonaList([]);
    }
  }, [selectedCorpusId, appData.data.results.config.corpora]);

  useEffect(() => {
    setIsActive(summary !== "" && persona !== "");
  }, [summary, persona]);

  useEffect(() => {
    if (performPersonaRewrite && summary) {
      personaRewrite();
    }
    setPerformPersonaRewrite(false);
  }, [performPersonaRewrite]);

  const updatePersona = (event) => {
    const selectedPersona = event.target.value;
    if (persona === selectedPersona) {
      setPersona("");
    } else {
      setPersona(selectedPersona);
    }
    setPerformPersonaRewrite(true);
  };

  const handleReset = () => {
    setPersona(null);
    setPersonaSummary(null);
  };

  const personaRewrite = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`api/v1/web/search/persona`, {
        summary: summary,
        persona: persona.name,
        language: language,
        parentRequestLogId: requestLogId,
        corpusId: selectedCorpusId
      });

      try {
        const prevSummary = summary;
        setPersonaSummary(response.data.results.text);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert("A server error occurred. Please try again later.");
        } else {
          alert("An error occurred. Please try again later.");
        }
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }
  };

  return (
    <Box sx={{display: "flex", flexDirection: "column", gap: 1, width: "100%"}}>
      <Stack direction="row" spacing={1}>
        <FormControl fullWidth>
          <InputLabel>
            Persona
          </InputLabel>
          {isLoading ? (
            <FaSpinner className={classes.spinner} />
          ) : (
            <Select
              value={persona}
              label="Persona"
              onChange={updatePersona}
              endAdornment={
                persona ? (
                  <InputAdornment position="end">
                    <IconButton className="icon" onClick={handleReset} size="small" sx={{marginRight: 1}}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null
              }
            >
              <MenuItem value="" disabled></MenuItem>
              {personaList.map((persona) => (
                <MenuItem key={persona.name} value={persona}>
                  {persona.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </Stack>
    </Box>
  );
};

export default SummaryPersona;

export const personaRewrite = async (
  summary,
  persona,
  language,
  requestLogId,
  selectedCorpusId
) => {
  try {
    const response = await axios.post(`api/v1/web/search/persona`, {
      summary: summary,
      persona: persona.name,
      instruction: persona.instruction,
      language: language,
      parentRequestLogId: requestLogId,
      corpusId: selectedCorpusId,
    });

    try {
      return response.data.results.text;
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert("A server error occurred. Please try again later.");
      } else {
        alert("An error occurred. Please try again later.");
      }
      return summary;
    }
  } catch (error) {
    console.error("Error:", error);
    return summary;
  }
};