import React, {useState, useEffect, useContext} from 'react';
import {SummaryContext} from './Interface.js'

import {
  FormControl, InputLabel, Select, MenuItem, Box, Typography, Paper, Button,
  IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

function CorpusSelector({setCorpusId, environmentVariables, resetInterface, inputPassword}) {
  const {appData, query, setQuery, summary, setSummary} = useContext(SummaryContext);
  const [corpora, setCorpora] = useState([]);
  const [selectedCorpusId, setSelectedCorpusId] = useState('');
  const [selectedCorpusBlurb, setSelectedCorpusBlurb] = useState('');
  const [selectedCorpusDescription, setSelectedCorpusDescription] = useState('');
  const [isCorpusSelected, setIsCorpusSelected] = useState(true);
  const [showDescription, setShowDescription] = useState(false);
  const [showContents, setShowContents] = useState(false);

  useEffect(() => {
    const corporaFromPassword = appData.data.results.config.corpora
    if (corporaFromPassword.length > 0) {
      setCorpora(corporaFromPassword);
      setSelectedCorpusId(corporaFromPassword[0].corpusId || '');
      setSelectedCorpusBlurb(corporaFromPassword[0].blurb || '');
      setSelectedCorpusDescription(corporaFromPassword[0].description || '');
      setCorpusId(corporaFromPassword[0].corpusId || '');
    } else {
      setCorpora([]);
    }
  }, [inputPassword]);

  const handleChange = (e) => {
    const newCorpusId = e.target.value || '';
    setSelectedCorpusId(e.target.value);
    setCorpusId(e.target.value);
    setIsCorpusSelected(true);
    resetInterface();

    const selectedCorpus = corpora.find(corpus => corpus.corpusId === newCorpusId);
    if (selectedCorpus) {
      setSelectedCorpusBlurb(selectedCorpus.blurb);
      setSelectedCorpusDescription(selectedCorpus.description);
    }
  };

  const openDescriptionPanel = () => {
    setShowDescription(true);
  };

  const closeDescriptionPanel = () => {
    setShowDescription(false);
  };

  const openContentsPanel = () => {
    setShowContents(true);
  };

  const closeContentsPanel = () => {
    setShowContents(false);
  };

  return (
    <Box sx={{width: '100%', maxWidth: '300px'}}>
      {corpora.length > 1 ? (
        <FormControl fullWidth>
          <InputLabel>
            Knowledge Base
          </InputLabel>
          <Select value={selectedCorpusId} label="Knowledge Base" onChange={handleChange}>
            {corpora.map((corpus) => (
              <MenuItem key={corpus.corpusId} value={corpus.corpusId}>
                {corpus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <Paper sx={{padding: 1, borderRadius: 1, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', background: '#dddddd'}}>
          <Typography variant="b1" component="div">
            {corpora[0]?.name}
          </Typography>
        </Paper>
      )}

      <Box sx={{display: 'flex', alignItems: 'center', marginTop: 1}}>
        <Typography variant="body1" sx={{flexGrow: 1, minWidth: 236}}>
          {selectedCorpusBlurb}
        </Typography>
        <IconButton className="icon" size="small" onClick={openDescriptionPanel}>
          <InfoIcon />
        </IconButton>
      </Box>

      {false && (
        <Box sx={{display: 'flex', alignItems: 'center', marginTop: 2}}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={openContentsPanel}
          >
            Explore Contents
          </Button>
        </Box>
      )}

      {showDescription && <Dialog open={openDescriptionPanel} onClose={closeDescriptionPanel}>
        <DialogTitle>Description</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize='14px'>
            {selectedCorpusDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDescriptionPanel}>Close</Button>
        </DialogActions>
      </Dialog>}

      {showContents && <Dialog open={openContentsPanel} onClose={closeContentsPanel}>
        <DialogTitle>Explore Contents</DialogTitle>
        <DialogContent>
          <DialogContentText fontSize='14px'>
            Coming soon - use AI to explore this knowledge base quickly to formualte your question!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeContentsPanel}>Close</Button>
        </DialogActions>
      </Dialog>}
    </Box>
  );
}

export default CorpusSelector;
