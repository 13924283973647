import React, {useState, useContext} from 'react';
import {SummaryContext} from './Interface.js';
import SummarySpeaker from './SummarySpeaker';
import SummaryFormatter from './SummaryFormatter';
import SummaryFeedback from './SummaryFeedback.js';
import {Box, Typography, IconButton, Tooltip, Paper, Divider} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import {makeStyles} from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  },
}));

function SummaryDisplay() {
  const {summary, setSummary, summaryRedirect, setSummaryRedirect, setHighlightedIndex, persona, personaSummary, reformattedSummary, summaryIsFallback, fallbackMessage, setFallbackMessage} = useContext(SummaryContext);
  const [feedbackVote, setFeedbackVote] = useState(null);
  const [feedbackText, setFeedbackText] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [votedUp, setVotedUp] = useState(false);
  const [votedDown, setVotedDown] = useState(false);
  const [copyToClipboard, setCopyToClipboard] = useState(false);

  const classes = useStyles();

  const handleReferenceEnter = (index) => {
    setHighlightedIndex(index);
  };

  const handleReferenceLeave = () => {
    setHighlightedIndex(null);
  };

  const handleReferenceClick = (index) => {
    document.getElementById(`response-${index}`).scrollIntoView({
      behavior: 'smooth'
    });
  };

  const updateFeedbackVote = async (vote) => {
    const response = await axios({
      method: 'POST',
      url: `/api/v1/web/search/feedback`,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      data: {
        "feedback": vote,
        "comments": feedbackText
      }
    });
    setFeedbackVote(vote);
    setFeedbackSubmitted(true);
  }

  const copySummaryToClipboard = () => {
    const cleanSummary = summary.replace(/\[\d+\]/g, '');
    navigator.clipboard.writeText(cleanSummary);
    setCopyToClipboard(true);
  };

  const splitSummaryReferences = (summary) => {
    return summary.replace(/\[(\d+,\d+)\]/g, (match, p1) => {
      return p1.split(',').map(num => `[${num}]`).join('');
    });
  };

  const renderFormattedSummary = (summary) => {
    return summary.split('\n').map((line, index) => (
      <span key={index}>
        {line}
        {line && <br />}
      </span>
    ));
  };

  const handleCloseSnackbar = () => {
    setVotedUp(false);
    setVotedDown(false);
    setCopyToClipboard(false);
  };

  const createFloatingAlert = () => {
    const alert = document.createElement("div");
    alert.innerText = "Summary copied!";
    alert.className = "floating-alert";
    document.body.appendChild(alert);

    setTimeout(() => {
      alert.classList.add("drift-away");
    }, 500);

    setTimeout(() => {
      document.body.removeChild(alert);
    }, 3000);
  }

  const renderSummaryText = (preprocessedSummary) => {
    const referenceRegEx = /\[(\d+)\]/g;
    const urlRegEx = /(https?:\/\/[^\s]+)/g;
    const splitSummary = preprocessedSummary.split(urlRegEx);

    return splitSummary.map((part, i) => {
      if (urlRegEx.test(part)) {
        return <a key={i} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
      } else {
        return part.split(referenceRegEx).map((innerPart, j) => {
          if (j % 2 === 0) {
            return <span key={`${i}-${j}`}>{innerPart}</span>;
          } else {
            const index = parseInt(innerPart, 10) - 1;
            return (
              <Tooltip key={`${i}-${j}`} title={`Go to reference ${innerPart}`}>
                <sup>
                  <Typography
                    component="span"
                    variant="body2"
                    className="reference"
                    onMouseEnter={() => handleReferenceEnter(index)}
                    onMouseLeave={handleReferenceLeave}
                    onClick={() => handleReferenceClick(index)}
                    sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#4b8bf5', '&:hover': { color: '#7faeff' } }}
                  >
                    [{innerPart}]
                  </Typography>
                </sup>
              </Tooltip>
            );
          }
        });
      }
    });
  };

  const preprocessedSummary = splitSummaryReferences(summary);

  if (summaryRedirect) {
    return (
      <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <Paper sx={{padding: 2, borderRadius: 1, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 2, background: '#eeeeee', width: '90%'}}>
          {/* <FaSpinner className={classes.spinner} size={24} /> */}
          <Typography variant="h6" sx={{marginTop: 2, marginBottom: 2}}>
            {fallbackMessage}
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{marginBottom: 2}}>
      {summary && (
        <Typography variant="h5" sx={{marginBottom: 2}}>Summary</Typography>
      )}
      <Paper sx={{padding: 2, borderRadius: 1, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)', marginBottom: 2, background: '#eeeeee'}}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 2
        }}>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <SummarySpeaker />
          </Box>
          <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <SummaryFeedback />
            <Tooltip title="Copy to Clipboard" arrow>
              <IconButton className="icon" onClick={copySummaryToClipboard} size="24px">
                <ContentCopyIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Typography variant="body1" sx={{lineHeight: 1.6, textAlign: 'left', whiteSpace: 'pre-line'}}>
          {renderSummaryText(preprocessedSummary)}
        </Typography>
        {summaryIsFallback && (
          <>
            <br></br>
            <Typography variant="b3" sx={{color: 'grey', fontSize: 14, lineHeight: 1.6, textAlign: 'left'}}>
              This summary was generated by a large language model and may potentially be inaccurate.
            </Typography>
          </>
        )}
        <Typography></Typography>

        {personaSummary && <> <br></br> <Divider sx={{bgcolor: "#888888", borderBottomWidth: 5}} /> </>}
        {personaSummary && (
          <Box sx={{marginTop: 2}}>
            <Typography variant="h6" >
              {persona.name} Summary
            </Typography>
            <Typography variant="body1" sx={{lineHeight: 1.6, textAlign: 'left', whiteSpace: 'pre-line'}}>
              {personaSummary}
            </Typography>
          </Box>
        )}

        {reformattedSummary && <> <br></br> <Divider sx={{bgcolor: "#888888", borderBottomWidth: 5}} /> <br></br> </>}
        <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2, marginTop: 2}}>
          <Box sx={{height: 80, minWidth: 200}}>
            <SummaryFormatter />
          </Box>
        </Box>

        {reformattedSummary && (
          <Box sx={{marginTop: 2}}>
            <br></br>
            <Typography variant="h6" >
              Reformatted Summary
            </Typography>
            <Typography variant="body1" sx={{lineHeight: 1.6, textAlign: 'left', whiteSpace: 'pre-line'}}>
              {renderFormattedSummary(reformattedSummary)}
            </Typography>
          </Box>
        )}

        <Snackbar
          open={copyToClipboard}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Summary copied to clipboard."
        />
        <Snackbar
          open={feedbackVote == true}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Thank you for your positive feedback."
        />
        <Snackbar
          open={feedbackVote == false}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Thank you for your negative feedback."
        />
      </Paper>
    </Box>
  );
};

export default SummaryDisplay;