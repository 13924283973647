function loadExternalStyling(url, id='ExternalStyle') {
  const head = document.head;
  let extCss = head.querySelector(`extCss#${id}`);

  if (extCss) {
    extCss.href = url;
  } else {
    extCss = document.createElement('link');
    extCss.id = id;
    extCss.rel = 'stylesheet';
    extCss.type = 'text/css';
    extCss.href = url;
    head.appendChild(extCss);
  }
}

export {loadExternalStyling};