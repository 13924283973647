import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiDoc = () => {
  const containerStyle = {
    margin: '20px auto',
    padding: '20px',
    width: '100%',
    height: 'calc(100vh - 40px)',
    overflowY: 'auto',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    borderRadius: '8px'
  };

  return (
    <div style={containerStyle}>
      <SwaggerUI url="/ApiDoc.json" />
    </div>
  );
};

export default ApiDoc;