import React, { useContext } from 'react';
import PasswordPage from './PasswordPage';
import { ThemeContext } from './index';


function App() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="app-container"  style={{ background: theme.palette.background.default, color: theme.palette.text.primary }}>
      <PasswordPage />
    </div>
  );
}

export default App;