import React, {useEffect, useState, useContext} from 'react';
import {SummaryContext} from './Interface.js';
import ResponseFeedback from './ResponseFeedback';
import {Paper, Typography, Button, Box, Link, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Table, TableBody, TableCell, TableRow} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Tooltip from '@mui/material/Tooltip';

const ResponseDisplay = () => {
  const {appData, responseList, documentList, selectedCorpusId, setSelectedMetadataFilter, setSourceUrl, setSourceMediaType, citeSource, handleSubmit, requestLogId} = useContext(SummaryContext);
  const [selectedMetadata, setSelectedMetadata] = useState({});
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [showDisplayContent, setShowDisplayContent] = useState(null);
  const [showSummary, setShowSummary] = useState(null);
  const [showInfo, setShowInfo] = useState(null);
  const selectedCorpus = appData.data.results.config.corpora.find(corpus => corpus.corpusId == selectedCorpusId);
  const resultInfoAttributesList = selectedCorpus && selectedCorpus.resultInfoAttributes && selectedCorpus.resultInfoAttributes.show
    ? selectedCorpus.resultInfoAttributes.tags
    : [];

  const getConfidenceLevel = (score) => {
    if (score > 0.5) {
      return {level: 'High', color: 'green' };
    } else if (score >= -0.2 && score <= 0.5) {
      return {level: 'Medium', color: 'yellow'};
    } else if (score < -0.2) {
      return {level: 'Low', color: 'red'};
    } else {
      return {level: 'Unknown', color: 'grey'};
    }
  }

  const condenseWhitespace = (text) => {
    return text.replace(/(\s*\n\s*){2,}/g, '\n\n');
  }

  const renderResponseText = (text) => {
    const snippetRegEx = /%START_SNIPPET%(.*?)%END_SNIPPET%/s;
    const urlRegEx = /(https?:\/\/[^\s]+)/g;

    const isValidUrl = (url) => {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    };

    const formatUrl = (url) => {
      return url.length > 32 ? `${url.slice(0, 32)}...` : url;
    };

    const parts = text.split(snippetRegEx);
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part.split(urlRegEx).map((innerPart, innerIndex) => {
          if (urlRegEx.test(innerPart) && isValidUrl(innerPart)) {
            return <a key={`${index}-${innerIndex}`} href={innerPart} target="_blank" rel="noopener noreferrer">{formatUrl(innerPart)}</a>;
          }
          return innerPart;
        });
      } else {
        const snippetParts = part.split(urlRegEx).map((snippetPart, snippetIndex) => {
          if (urlRegEx.test(snippetPart) && isValidUrl(snippetPart)) {
            return <a key={`${index}-${snippetIndex}`} href={snippetPart} target="_blank" rel="noopener noreferrer">{formatUrl(snippetPart)}</a>;
          }
          return snippetPart;
        });
        return (
          <span key={index} style={{fontWeight: 'bold', fontSize: "17px", backgroundColor: '#add8e8', color: '#222222'}}>
            {snippetParts}
          </span>
        );
      }
    });
  };

  useEffect(() => {
    setSelectedMetadataFilter({key: '', operator: '', value: ''});
  }, [setSelectedMetadataFilter]);

  useEffect(() => {
    const initialSelectedMetadata = {};
    responseList.forEach((_, index) => {
      initialSelectedMetadata[index] = JSON.stringify({key: '', operator: '', value: ''});
    });
    setSelectedMetadata(initialSelectedMetadata);
  }, [responseList]);

  useEffect(() => {
    if (isReadyToSubmit) {
      handleSubmit();
      setIsReadyToSubmit(false);
    }
  }, [isReadyToSubmit, handleSubmit]);

  const updateSource = (url, mediaType) => {
    setSourceUrl(url);
    setSourceMediaType(mediaType);
  }

  const handleFilterChange = (documentIndex, filterString, index) => {
    const filter = JSON.parse(filterString);
    setSelectedMetadataFilter(filter);

    if (filter.key === 'id') {
      const url = documentList[documentIndex]?.metadata['url'] || documentList[documentIndex]?.metadata['og:url'] || '';
      const mediaType = documentList[documentIndex]?.metadata['mediaType'] || '';
      updateSource(url, mediaType);
    }

    setIsReadyToSubmit(true);
  };

  const openDisplayContentPanel = (index) => {
    setShowDisplayContent(index);
  };

  const closeDisplayContentPanel = () => {
    setShowDisplayContent(null);
  };

  const openSummaryPanel = (index) => {
    setShowSummary(index);
  };

  const closeSummaryPanel = () => {
    setShowSummary(null);
  };

  const openInfoPanel = (index) => {
    setShowInfo(index);
  };

  const closeInfoPanel = () => {
    setShowInfo(null);
  };

  return (
    <Box sx={{marginBottom: 2}}>
      {responseList.length > 0 &&
        <Typography variant="h5" sx={{marginTop: 2, marginBottom: 2}}>
          Search Results
        </Typography>
      }
      {responseList.map((response, index) => {
        const documentMetadata = documentList[response.documentIndex]?.metadata;
        const lowercaseDocumentMetadata = Object.keys(documentMetadata).reduce((acc, key) => {
          acc[key.toLowerCase()] = documentMetadata[key];
          return acc;
        }, {});
        const documentId = documentList[response.documentIndex]['id'];
        const citationUrl = documentMetadata['url'] || documentMetadata['og:url'] || '';
        const selectedCorpusMetadataAttributes = appData.data.results.config.corpora.find(corpus => corpus.corpusId.toString() === selectedCorpusId)?.metadataAttributes.tags;

        return (
          <Paper key={index} id={`response-${index}`} sx={{padding: 2, marginBottom: 2, background: "#eeeeee", position: 'relative'}}>
            <Box sx={{position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
              <Typography variant="body2" sx={{backgroundColor: '#D5D8DC', color:'#141618', padding: '2px 5px', borderRadius: '3px'}}>
                {documentMetadata['source'] && (
                  <span>Source: {documentMetadata['source']} | </span>
                )}
                 Relevancy: {response.score.toFixed(2)}
              </Typography>
            </Box>
            <Typography variant="h6" sx={{marginBottom: 1}}>
              #{index + 1}
            </Typography>
            <Typography variant="body1" sx={{textAlign: 'left', whiteSpace: 'pre-line', wordBreak: 'break-word', overflowWrap: 'break-word'}}>
              {renderResponseText(response.text)}
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2}}>
              {citeSource && (
                <Box sx={{textAlign: 'left', marginTop: 2}}>
                  <Typography variant="body2" display="block" gutterBottom>
                    <Box component="span" sx={{display: 'block', color: '#444444', marginBottom: 1}}>
                      {documentId}
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 2}}>
                      {citationUrl && (
                        <Link href={citationUrl} target="_blank" rel="noopener noreferrer" sx={{verticalAlign: 'middle'}}>
                          Source
                        </Link>
                      )}
                      {selectedCorpus.resultInfoAttributes && selectedCorpus.resultInfoAttributes.show && (
                        <Link component="button" onClick={() => openInfoPanel(index)} sx={{verticalAlign: 'middle'}}>
                          Source Info
                        </Link>
                      )}
                      {response.displayContent && (
                        <Link component="button" onClick={() => openDisplayContentPanel(index)} sx={{verticalAlign: 'middle'}}>
                          Source Content
                        </Link>
                      )}
                      {response.summary && (
                        <Link component="button" onClick={() => openSummaryPanel(index)} sx={{verticalAlign: 'middle'}}>
                          Source Summary
                        </Link>
                      )}
                    </Box>
                  </Typography>
                </Box>
              )}
              <Button
                variant="contained"
                className="button"
                sx={{marginLeft: 1}}
                onClick={() => {
                  const newFilter = JSON.stringify({key: 'id', operator: '=', value: response.id});
                  handleFilterChange(response.documentIndex, newFilter, index);
                  setSelectedMetadata({...selectedMetadata, [index]: newFilter});
                }}
              >
                Search This Source
              </Button>
            </Box>
            <Box sx={{textAlign: 'right'}}>
              <ResponseFeedback
                resultInfo={{
                  index: (response.documentIndex + 1),
                  metadata: response.metadata,
                  score: response.score,
                  documentId: response.id,
                  text: response.text,
                }}
                parentRequestLogId={requestLogId}
              />
            </Box>
            {showInfo === index && (
              <Dialog open={showInfo === index} onClose={closeInfoPanel}>
                <DialogTitle>
                  Source Information
                </DialogTitle>
                <DialogContent>
                  <Table sx={{borderCollapse: 'collapse'}}>
                    <TableBody>
                    {resultInfoAttributesList && resultInfoAttributesList
                      .filter((attr) => {
                        const lowercaseTag = attr.tag.toLowerCase();
                        const value = lowercaseDocumentMetadata[lowercaseTag];
                        return value !== undefined;
                      })
                      .map((attr) => {
                        const lowercaseTag = attr.tag.toLowerCase();
                        const value = lowercaseDocumentMetadata[lowercaseTag];
                        return (
                          <TableRow key={attr.tag}>
                            <TableCell sx={{border: '1px solid #ddd', borderRight: '1px solid #ddd', padding: '8px', fontWeight: 'bold'}}>
                              <Tooltip title={attr.description || ''} placement="left" arrow>
                                <span>
                                  {attr.title}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell sx={{border: '1px solid #ddd', padding: '8px'}}>
                              {value}
                            </TableCell>
                          </TableRow>
                        );
                      }).length === 0 ? (
                        <Typography variant="body2" sx={{padding: '16px', textAlign: 'left'}}>
                          No source information found.
                        </Typography>
                      ) : (
                        resultInfoAttributesList
                          .filter((attr) => {
                            const lowercaseTag = attr.tag.toLowerCase();
                            const value = lowercaseDocumentMetadata[lowercaseTag];
                            return value !== undefined;
                          })
                          .map((attr) => {
                            const lowercaseTag = attr.tag.toLowerCase();
                            const value = lowercaseDocumentMetadata[lowercaseTag];
                            return (
                              <TableRow key={attr.tag}>
                                <TableCell sx={{border: '1px solid #ddd', borderRight: '1px solid #ddd', padding: '8px', fontWeight: 'bold'}}>
                                  <Tooltip title={attr.description || ''} placement="left" arrow>
                                    <span>
                                      {attr.title}
                                    </span>
                                  </Tooltip>
                                </TableCell>
                                <TableCell sx={{border: '1px solid #ddd', padding: '8px'}}>
                                  {value}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      )}
                    </TableBody>
                  </Table>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeInfoPanel}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {showDisplayContent === index && response.displayContent && response.displayContent.text && (
              <Dialog open={showDisplayContent === index} onClose={closeDisplayContentPanel}>
                <DialogTitle>
                  Source Content
                </DialogTitle>
                <DialogContent>
                  <DialogContentText fontSize='14px'>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {response.displayContent.text}
                    </ReactMarkdown>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeDisplayContentPanel}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {showSummary === index && (
              <Dialog open={showSummary === index} onClose={closeSummaryPanel}>
                <DialogTitle>
                  Source Summary
                </DialogTitle>
                <DialogContent>
                  <DialogContentText fontSize='14px'>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {response.summary}
                    </ReactMarkdown>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={closeSummaryPanel}>
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Paper>
        );
      })}
    </Box>
  );
};

export default ResponseDisplay;