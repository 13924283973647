import React, { useEffect, useState, createContext } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Provider } from "./provider";

// Create a context for theme
export const ThemeContext = createContext();

const lightTheme = createTheme({
  palette: {
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#000000",
    },
    primary: {
      main: "#1565c0",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

const darkTheme = createTheme({
  palette: {
    background: {
      default: "#171717",
      paper: "#121212",
    },
    text: {
      primary: "#ffffff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    primary: {
      main: "#B3B3B3",
    },
  },
  buttons: {
    action: {
      active: "#fff",
      hover: "rgba(255, 255, 255, 0.08)",
      selected: "#282828",
      disabled: "#282828",
      disabledBackground: "#282828",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  divider: "rgba(255, 255, 255, 0.12)",
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: "#B3B3B3",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#B3B3B3",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
          color: "#B3B3B3",
          "& .MuiTableCell-root": {
            borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#121212",
          color: "#B3B3B3",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "#B3B3B3",
          color: "#121212",
          "&:nth-of-type(even)": {
            backgroundColor: "#121212",
          },
          "&:nth-of-type(odd)": {
            backgroundColor: "rgba(255, 255, 255, 0.12)",
          },
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "#333333",
            color: "#ffffff",
          },
        },
      },
    },
  },
});

const Root = () => {
  const [theme, setTheme] = useState(lightTheme);

  useEffect(() => {
    const savedTheme = localStorage.getItem("appTheme");
    if (savedTheme) {
      setTheme(savedTheme === "light" ? lightTheme : darkTheme);
    }
  }, []);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === lightTheme ? darkTheme : lightTheme
    );
  };

  return (
    <Provider>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ThemeContext.Provider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <Root />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
