import React, {useState} from 'react';
import {Container, TextField, Button, Box} from '@mui/material';
import axios from 'axios';

const MyProfile = (userData) => {
  const [userId, setUserId] = useState(userData.userData.id);
  const [name, setName] = useState(userData.userData.name);
  const [emailAddress, setEmailAddress] = useState(userData.userData.email);
  const [password, setPassword] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNameSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put('/api/v1/web/admin/user', {
        id: userData.userData.id,
        name: name
      });
      alert('Successfully updated name.');
    } catch (error) {
      alert('Error updating name.');
    }
  };

  const handleEmailAddressSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put('/api/v1/web/admin/user', {
        id: userData.userData.id,
        email: emailAddress
      });
      alert('Successfully updated email address.');
    } catch (error) {
      alert('Error updating email address.');
    }
  };

  const handlePasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.put('/api/v1/web/admin/user', {
        id: userData.userData.id,
        password: password,
      });
      alert('Successfully updated password.');
      const logoutResponse = await axios.put('/api/v1/web/auth/logout');
      window.location.reload();
    } catch (error) {
      alert('Error updating password.');
    }
  };

  return (
    <Box>
      <Box sx={{flex: 1, overflow: 'auto'}}>
        <Container component="main" maxWidth="xs" sx={{marginTop: 8, marginBottom: 8, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',width:"400px"}}>
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="New Name"
                variant="outlined"
                value={name}
                onChange={handleNameChange}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{marginTop: 1, marginBottom: 2}}
                onClick={handleNameSubmit}
                disabled={!name}
              >
                Update Name
              </Button>
            </>
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="New Email Address"
                variant="outlined"
                value={emailAddress}
                onChange={handleEmailAddressChange}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{marginTop: 1, marginBottom: 2}}
                onClick={handleEmailAddressSubmit}
                disabled={!emailAddress}
              >
                Update Email Address
              </Button>
            </>
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                label="New Password"
                type="password"
                variant="outlined"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button
                fullWidth
                variant="contained"
                sx={{marginTop: 1, marginBottom: 2}}
                onClick={handlePasswordSubmit}
                disabled={!password}
              >
                Update Password
              </Button>
            </>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default MyProfile;