import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import defaultLogo from "./logo/logo.png";
import { AppBar, Toolbar, Typography, Box, Button } from "@mui/material";
import { gsap } from "gsap";
import { ThemeContext } from "./index";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import "./DefaultStyle.css";

function Header({
  setEmailAddress,
  setInputPassword,
  isAuthenticated,
  setIsAuthenticated,
  setDisplayComponent,
  userRole,
  clientLogoUrl,
}) {
  const { theme, toggleTheme } = useContext(ThemeContext); // Access theme and toggleTheme from context
  const [isDarkMode, setIsDarkMode] = useState(theme === "dark"); // Track the current theme mode

  const handleLogout = async () => {
    try {
      await axios.put("/api/v1/web/auth/logout");
      setEmailAddress("");
      setInputPassword("");
      setIsAuthenticated(false);
      setIsAnimationTriggered(false);
    } catch (error) {
      console.error("Error logging out:", error);
      if (error.response) {
        const message =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : "Failed to logout. Please try again.";
        alert(`Logout Error: ${message}`);
      } else {
        alert("Server error.");
      }
    }
  };

  const handleImageError = (e) => {
    e.target.src = defaultLogo;
  };

  const buttonStyle = {
    textTransform: "none",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "16px",
    color: "#C6C6C6",
    "&.active": {
      color: "#fff",
    },
  };

  const [activeComponent, setActiveComponent] = useState("Interface");
  const [isAnimationTriggered, setIsAnimationTriggered] = useState(false);

  const handleButtonClick = (component) => {
    setActiveComponent(component);
    setDisplayComponent(component);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setDisplayComponent("Interface");
      setActiveComponent("Interface");
      setIsAnimationTriggered(true);
    }
  }, [isAuthenticated, setDisplayComponent]);

  useEffect(() => {
    if (isAnimationTriggered) {
      const text = "Answer Assistant";
      const textContainer = document.getElementById("animated-text");

      if (textContainer) {
        textContainer.innerHTML = text
          .split("")
          .map((char) => `<span>${char}</span>`)
          .join("");

        gsap.fromTo(
          "#animated-text span",
          { opacity: 0, y: -20 },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.1,
            ease: "power1.out",
          }
        );
      }
    }
  }, [isAnimationTriggered]);

  const handleThemeToggle = () => {
    toggleTheme();
    setIsDarkMode(!isDarkMode);
  };

  return (
    <AppBar
      className="header"
      position="static"
      elevation={0}
      sx={{
        background: "#64aced",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        zIndex: "999",
        height: "4em",
      }}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "0",
            left: "0",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            height: "4em",
          }}
        >
          <img
            src={isAuthenticated && clientLogoUrl ? clientLogoUrl : defaultLogo}
            alt={
              isAuthenticated && clientLogoUrl ? "Client Logo" : "IgniteIQ Logo"
            }
            style={{ height: "40px", marginLeft: "20px" }}
            onError={handleImageError}
          />
          <Typography
            variant="h5"
            sx={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
            }}
          >
            {isAuthenticated ? (
              <div id="animated-text"></div>
            ) : (
              <div>Answer Assistant</div>
            )}
          </Typography>
          {isAuthenticated && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Button
                color="inherit"
                onClick={() => handleButtonClick("Interface")}
                sx={{
                  ...buttonStyle,
                  ...(activeComponent === "Interface" && {
                    color: "#fff",
                  }),
                }}
              >
                Search
              </Button>
              <Button
                color="inherit"
                onClick={() => handleButtonClick("MyProfile")}
                sx={{
                  ...buttonStyle,
                  ...(activeComponent === "MyProfile" && {
                    color: "#fff",
                  }),
                }}
              >
                Profile
              </Button>
              {userRole === "admin" && (
                <Button
                  color="inherit"
                  onClick={() => handleButtonClick("Administration")}
                  sx={{
                    ...buttonStyle,
                    ...(activeComponent === "Administration" && {
                      color: "#fff",
                    }),
                  }}
                >
                  Administration
                </Button>
              )}
              {userRole === "admin" && (
                <Button
                  color="inherit"
                  onClick={() => handleButtonClick("ApiDoc")}
                  sx={{
                    ...buttonStyle,
                    ...(activeComponent === "ApiDoc" && {
                      color: "#fff",
                    }),
                  }}
                >
                  API
                </Button>
              )}
              <Button color="inherit" onClick={handleLogout} sx={buttonStyle}>
                Logout
              </Button>
              {/* <Button
                color="inherit"
                onClick={handleThemeToggle}
                sx={{buttonStyle}}
              >
                {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </Button> */}
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
