import React, {useState, useRef, useContext, useEffect} from 'react';
import axios from 'axios';
import {SummaryContext} from './Interface.js';
import {FaSpinner} from 'react-icons/fa';
import {IconButton, Box, Tooltip} from '@mui/material';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {transform: "rotate(0deg)"},
    "100%": {transform: "rotate(360deg)"},
  },
  audioControlPanel: {
    padding: 2,
    borderRadius: 4,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
    background: '#add8e8',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '56px'
  },
});

const SummarySpeaker = () => {
  const {summary, newQuerySubmitted, setNewQuerySubmitted, audioEnabled, translationUpdated, setTranslationUpdated, requestLogId} = useContext(SummaryContext);
  const [isPlaying, setIsPlaying] = useState(true);
  const [audioGenerated, setAudioGenerated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const audioRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (newQuerySubmitted) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = null;
      }
      setIsPlaying(false);
      setAudioGenerated(false);
      setNewQuerySubmitted(false);
    }

    const updateProgress = () => {
      if (audioRef.current) {
        const {currentTime, duration} = audioRef.current;
        setProgress((currentTime / duration) * 100);
      }
    };

    audioRef.current?.addEventListener('timeupdate', updateProgress);

    return () => {
      audioRef.current?.removeEventListener('timeupdate', updateProgress);
    };
  }, [summary]);

  useEffect(() => {
    if (translationUpdated) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = null;
      }
      setIsPlaying(false);
      setAudioGenerated(false);
      setTranslationUpdated(false);
    }
  }, [translationUpdated]);

  const handleProgressChange = (event, newValue) => {
    if (audioRef.current) {
      const newTime = (audioRef.current.duration / 100) * newValue;
      audioRef.current.currentTime = newTime;
    }
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const generateAudio = async () => {
    setIsLoading(true);
    try {
      const cleanSummary = summary.replace(/\[\d+\]/g, '');
      const response = await axios.post(`/api/v1/web/search/textToSpeech`, {text: cleanSummary, parentRequestLogId:requestLogId}, {responseType: 'blob'});

      if (response.status === 200 && response.data) {
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);

        if (audioRef.current) {
          audioRef.current.src = audioUrl;
          audioRef.current.load();
          audioRef.current.play().catch(error => {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
          });
          setAudioGenerated(true);
          setIsPlaying(true);
        } else {
          console.error('audioRef is null.');
        }
      } else {
        console.error('Text-to-speech error.');
        alert('A server error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert('A server error occurred. Please try again later.');
      } else {
        alert('An error occurred. Please try again later.');
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const restartAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      if (!isPlaying) {
        setIsPlaying(true);
      }
      audioRef.current.play();
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {audioEnabled && (
        <>
          {isLoading ? (
            <IconButton
              className="icon"
              size="small"
            >
              <FaSpinner className={classes.spinner} />
            </IconButton>
          ) : (
            !audioGenerated && (
              <Tooltip title="AI-Generated Audio" arrow>
                <IconButton
                  className="icon"
                  onClick={generateAudio}
                  size="24px"
                >
                  <HeadphonesIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )
          )}
          <audio
            ref={audioRef}
            controls
            onLoadedData={playAudio}
            style={{width: "400px", height: "24px"}}
            hidden={!audioGenerated}
          />
        </>
      )}
    </Box>
  );
};

export default SummarySpeaker;