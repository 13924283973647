import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import CorpusSelector from "./CorpusSelector";
import SourceFilter from "./SourceFilter.js";
import SuggestedQuestions from "./SuggestedQuestions.js";
import SummaryDisplay from "./SummaryDisplay";
import SummarySpeaker from "./SummarySpeaker";
import SummaryTranslator from "./SummaryTranslator";
import SummaryPersona from "./SummaryPersona";
import ResponseDisplay from "./ResponseDisplay";
import FilterTag from "./FilterTag";
import { translateSummary } from "./SummaryTranslator";
import { personaRewrite } from "./SummaryPersona";
import { reformatSummary } from "./SummaryFormatter";
import { BrowserRouter as Router } from "react-router-dom";
import { loadExternalStyling } from "./ExternalStyling.js";
import { FaSpinner } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import { AppBar,Toolbar,IconButton,CssBaseline,Drawer,  List,  ListItem,  ListItemText,  TextField,  Button,  Box,
  Grid,  Paper,  Typography,  Link,  Divider,  FormControl,  InputLabel,  Select,  MenuItem,  FormControlLabel,  Checkbox,
  Slider,  FormLabel} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShareIcon from "@mui/icons-material/Share";
import SaveIcon from "@mui/icons-material/Save";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import VectaraChatbot from "./VectaraChatbot.js";
// import Vchatbot from "./Vchatbot.js"
// import { ReactChatbot } from "@vectara/react-chatbot";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent:'space-evenly',
    fontFamily: "sans-serif",
    height: "100vh",
    "&::-webkit-scrollbar": {
      width: "0 !important",
    },
  },
  sidebar: {
    maxWidth: "300px",
    height: "100vh",
    padding: "3.5em 0",
    flexShrink: 0,
    overflowY: "auto",
    "&.scrollable": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      backgroundColor: "#878887",
    },
  },
  content: {
    flexGrow: 1,
    padding: "5em 1em",
    minWidth:"700px",
    width: `calc(100% - 300px)`,
    maxHeight: "100vh",
    overflowY: "auto",
    "&.scrollable": {
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "8px",
      backgroundColor: "#878887",
    },
  },
  // sidebarPlaceholder: {
  //   width: '100%',
  //   height: '100%',
  //   backgroundColor: '#f0f0f0',
  // },
  // contentPlaceholder: {
  //   width: 'calc(100% - 300px)',
  //   minWidth: 'calc(100% - 300px)',
  //   height: '100%',
  //   backgroundColor: '#f0f0f0',
  // },

  footerButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
    gap: theme.spacing(1),
  },
  spinner: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

export const SummaryContext = createContext({
  appData: "",
  setAppData: () => {},
  query: "",
  setQuery: () => {},
  suggestedQuestions: [],
  setSuggestedQuestions: () => {},
  summary: "",
  setSummary: () => {},
  summaryRedirect: false,
  setSummaryRedirect: () => {},
  responseList: [],
  setResponseList: () => {},
  documentList: [],
  setDocumentList: () => {},
  selectedCorpusId: "",
  setSelectedCorpusId: () => {},
  highlightedIndex: null,
  setHighlightedIndex: () => {},
  selectedDocument: null,
  setSelectedDocument: () => {},
  selectedDocumentUrl: null,
  setSelectedDocumentUrl: () => {},
  selectedMetadataFilter: { key: "", operator: "", value: "" },
  setSelectedMetadataFilter: () => {},
  sourceUrl: "",
  setSourceUrl: () => {},
  sourceMediaType: "",
  setSourceMediaType: () => {},
  audioEnabled: true,
  setAudioEnabled: () => {},
  citeSource: true,
  setCiteSource: () => {},
  language: "English",
  setLanguage: () => {},
  currentLanguage: "English",
  setCurrentLanguage: () => {},
  translationUpdated: false,
  setTranslationUpdated: () => {},
  handleSubmit: () => {},
  persona: "",
  setPersona: () => {},
  personaSummary: "",
  setPersonaSummary: () => {},
  formatInstructions: "",
  setFormatInstructions: () => {},
  reformattedSummary: "",
  setReformattedSummary: () => {},
  summaryIsFallback: false,
  setSummaryIsFallback: () => {},
  searchMethod: "hybrid",
  setSearchMethod: () => {},
  filters: [],
  setFilters: () => {},
  emptyFilters: [],
  setEmptyFilters: () => {},
  requestLogId: "",
  setRequestLogId: () => {},
  fallbackMessage: "",
  setFallbackMessage: () => {},
  clearFiltersTrigger: false,
  setClearFiltersTrigger: () => {},
});

function Interface({
  inputPassword,
  userData,
  setSubscriptionStartDate,
  setIsAuthenticated,
  setEmailAddress,
  setInputPassword,
  setClientLogoUrl,
}) {
  const [appData, setAppData] = useState();
  const [query, setQuery] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [summary, setSummary] = useState("");
  const [summaryRedirect, setSummaryRedirect] = useState(false);
  const [responseList, setResponseList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [selectedCorpusId, setSelectedCorpusId] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState(null);
  const [metadataFilterAttributesList, setMetadataFilterAttributesList] =
    useState([]);
  const [selectedMetadataFilter, setSelectedMetadataFilter] = useState({
    key: "",
    operator: "",
    value: "",
  });
  const [sourceUrl, setSourceUrl] = useState("");
  const [sourceMediaType, setSourceMediaType] = useState("");
  const [newQuerySubmitted, setNewQuerySubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const [numResults, setNumResults] = useState(5);
  const [numSummarizedResults, setNumSummarizedResults] = useState(5);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [language, setLanguage] = useState("English");
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const [translationUpdated, setTranslationUpdated] = useState(false);
  const [citeSource, setCiteSource] = useState(true);
  const [shareButton, setShareButton] = useState(false);
  const [saveSearchButton, setSaveSearchButton] = useState(false);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [persona, setPersona] = useState("");
  const [personaSummary, setPersonaSummary] = useState("");
  const [formatInstructions, setFormatInstructions] = useState("");
  const [reformattedSummary, setReformattedSummary] = useState("");
  const [searchMethod, setSearchMethod] = useState("hybrid");
  const [summaryIsFallback, setSummaryIsFallback] = useState(false);
  const [filterTagOptions, setFilterTagOptions] = useState(null);
  const [filters, setFilters] = useState([]);
  const [emptyFilters, setEmptyFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [requestLogId, setRequestLogId] = useState("");
  const [fallbackMessage, setFallbackMessage] = useState("");
  const [clearFiltersTrigger, setClearFiltersTrigger] = useState(false);
  const [sidebarScroll, setSidebarScroll] = useState(false);
  const [mainbodyScroll, setmainbodyScroll] = useState(false);
  const [preloader, setPreloader] = useState(true);
  const [contentReady, setContentReady] = useState(false);

  const classes = useStyles();

  const validateAppConfig = (appConfig) => {
    if (!appConfig) {
      return false;
    }
    if (
      !appConfig.appLogo ||
      !appConfig.appFooter ||
      !appConfig.welcomeMessage
    ) {
      console.log("appConfig validation error.");
      return false;
    }
    if (!Array.isArray(appConfig.corpora) || appConfig.corpora.length === 0) {
      console.log("appConfig validation error.");
      return false;
    }
    for (const corpus of appConfig.corpora) {
      if (
        !corpus.corpusId ||
        !corpus.name ||
        !corpus.blurb ||
        !corpus.description
      ) {
        console.log("appConfig validation error.");
        return false;
      }
      if (!corpus.metadataAttributes) {
        console.log("appConfig validation error.");
        return false;
      }
      for (const tag of corpus.metadataAttributes.tags) {
        if (
          !tag.title ||
          !tag.tag ||
          !tag.operator ||
          typeof tag.inputControl !== "string"
        ) {
          console.log("appConfig validation error.");
          return false;
        }
      }
      if (!corpus.filterContent) {
        console.log("appConfig validation error.");
        return false;
      }
      if (!corpus.language) {
        console.log("appConfig validation error.");
        return false;
      }
      if (!corpus.persona) {
        console.log("appConfig validation error.");
        return false;
      }
      if (typeof corpus.enableAudio.show !== "boolean") {
        console.log("appConfig validation error.");
        return false;
      }
      if (!corpus.citeSource) {
        console.log("appConfig validation error.");
        return false;
      }
      if (!Array.isArray(corpus.sampleQuestions)) {
        console.log("appConfig validation error.");
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const response = await axios({
          method: "GET",
          url: `/api/v1/web/app`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const appConfig = response.data.results.config;
        if (validateAppConfig(appConfig)) {
          setAppData(response);
          setClientLogoUrl(appConfig.appLogo);
          try {
            if (appConfig.appStyle) {
              loadExternalStyling(appConfig.appStyle);
            } else {
              console.error("Failed to load external CSS style sheet.");
            }
          } catch (error) {
            console.error("Failed to load external CSS style sheet.", error);
          }
        } else {
          alert(
            "Your application configuration is not valid. Please contact your administrator with the following error message:\n\nERROR_INVALID_CONFIG"
          );
          setEmailAddress("");
          setInputPassword("");
          await axios.put("/api/v1/web/auth/logout");
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Failed to fetch application data:", error);
        alert(
          "The application configuration is invalid. Please contact your administrator with the following error message:\n\nERROR_INVALID_APPCONFIG"
        );
      } finally {
        setContentReady(true);
        setTimeout(() => {
          setPreloader(false);
        });
      }
    };
    const timer = setTimeout(() => {
      fetchAppData();
    });

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!appData || !selectedCorpusId) {
      return;
    }
    setSubscriptionStartDate(appData.data.results.subscriptionStartedAt);

    const selectedCorpus = appData.data.results.config.corpora.find(
      (corpus) => corpus.corpusId == selectedCorpusId
    );
    if (!selectedCorpus) return;
    const defaultLanguage =
      selectedCorpus.language && selectedCorpus.language.default
        ? selectedCorpus.language.default
        : "English";
    setLanguage(defaultLanguage);
    setCurrentLanguage(defaultLanguage);

    if (selectedCorpusId && selectedCorpus) {
      const suggestedSampleQuestions = selectedCorpus.sampleQuestions.map(
        (question) => ({
          question,
          similarityScore: null,
        })
      );
      setSuggestedQuestions(suggestedSampleQuestions);
      try {
        setFallbackMessage(selectedCorpus.fallbackMessage);
      } catch (error) {
        setFallbackMessage(
          "The returned results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or rephrase your query differently."
        );
      }
    } else {
      setNumResults(4);
      setNumSummarizedResults(2);
    }
    if (selectedCorpus.metadataAttributes.show) {
      const metadataFilterAttributes =
        selectedCorpus.metadataAttributes.tags.map((tag) => tag.tagName);
      setMetadataFilterAttributesList(metadataFilterAttributes);
      initCorpusFilters(selectedCorpus);
    }
  }, [selectedCorpusId, appData]);

  useEffect(() => {
    const fetchAndSetFilterTagOptions = async () => {
      if (!selectedCorpusId || !appData) return;

      const selectedCorpus = appData.data.results.config.corpora.find(
        (corpus) => corpus.corpusId === selectedCorpusId
      );
      if (!selectedCorpus || !selectedCorpus.metadataAttributes.show) return;
      setShowFilters(selectedCorpus.metadataAttributes.show);

      try {
        const response = await axios.get(`/api/v1/web/app/filterTagOptions`, {
          params: {
            corporaId: selectedCorpusId,
          },
        });
        const tagOptionsFromApi = response.data.results;
        if (!tagOptionsFromApi || tagOptionsFromApi.length === 0) {
          setFilterTagOptions(null);
        } else {
          const updatedFilterTagOptions =
            selectedCorpus.metadataAttributes.tags.map((tag) => {
              const tagOptions = tagOptionsFromApi.find(
                (option) => option.tag.toLowerCase() === tag.tag.toLowerCase()
              );
              return {...tag, options: tagOptions ? tagOptions.values : []};
            });
          setFilterTagOptions(updatedFilterTagOptions);
        }
      } catch (error) {
        console.error("Failed to fetch filter tag options: ", error);
        setFilterTagOptions(null);
      }
    };

    fetchAndSetFilterTagOptions();
  }, [selectedCorpusId, appData]);

  useEffect(() => {
    if (!appData || !selectedCorpusId) return;

    const selectedCorpus = appData.data.results.config.corpora.find(
      (corpus) => corpus.corpusId === selectedCorpusId
    );
    if (selectedCorpus) {
      initCorpusFilters(selectedCorpus);
    }
  }, [selectedCorpusId, appData]);

  useEffect(() => {
    if (query && submitTrigger) {
      setSubmitTrigger(false);
      handleSubmit();
    }
  }, [submitTrigger, query]);

  useEffect(() => {
    if (selectedMetadataFilter === null && isReadyToSubmit) {
      handleSubmit();
      setIsReadyToSubmit(false);
    }
  }, [selectedMetadataFilter, isReadyToSubmit]);

  const initCorpusFilters = (corpus) => {
    if (!corpus) {
      return null;
    }
    if (corpus.metadataAttributes && corpus.metadataAttributes.show) {
      const tmpEmptyFilters = corpus.metadataAttributes.tags.map(
        ({ tag, operator }) => [tag, operator, null]
      );
      setFilters(tmpEmptyFilters);
      setEmptyFilters(tmpEmptyFilters);
    } else {
      setFilters([]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitTrigger((prev) => !prev);
    }
  };

  const resetInterface = () => {
    setQuery("");
    setSummary("");
    setResponseList([]);
    setSelectedDocument(null);
    setSelectedMetadataFilter({ key: "", operator: "", value: "" });
    setPersona("");
    setPersonaSummary("");
    setReformattedSummary("");
  };

  const handleSampleQuestion = (question) => {
    setQuery(question);
    handleButtonClick();
  };

  const handleButtonClick = () => {
    setSubmitTrigger((prev) => !prev);
  };

  const clickShareButton = () => {
    setShareButton(true);
  };

  const clickSaveSearchButton = () => {
    setSaveSearchButton(true);
  };

  const handleCloseSnackbar = () => {
    setShareButton(false);
    setSaveSearchButton(false);
  };

  const updateSemanticSearchStrength = (event) => {
    setSearchMethod(event.target.value);
  };

  const translateText = async (text) => {
    if (!text) {
      return text;
    }
    try {
      const response = await axios.post(`/api/v1/web/search/translate`, {
        text: text,
        language: language,
      });
      return response.data.results.text;
    } catch (error) {
      return text;
    }
  };

  const handleSubmit = async () => {
    if (!selectedCorpusId) {
      alert("Please select a knowledge base first.");
      return;
    }
    const selectedCorpus = appData.data.results.config.corpora.find(
      (corpus) => corpus.corpusId == selectedCorpusId
    );
    const defaultQuerySettings = {
      keyword: {
        resultsRelevancyThreshold: 0.8,
        lambdaValue: 1,
        llmTemperature: 0,
      },
      hybrid: {
        resultsRelevancyThreshold: 0.7,
        lambdaValue: 0.025,
        llmTemperature: 0,
      },
      semantic: {
        resultsRelevancyThreshold: 0.6,
        lambdaValue: 0,
        llmTemperature: 0,
      },
    };
    const querySettings =
      selectedCorpus.querySettings?.[searchMethod] ||
      defaultQuerySettings[searchMethod];

    setIsLoading(true);

    const submitSearchQuery = async (maxResultsToSummarize) => {
      let requestData = {
        corpusId: parseInt(selectedCorpusId),
        query: query,
//        numberOfResults: numResults,
//        maxResultsToSummarize: maxResultsToSummarize || numSummarizedResults,
        searchMethod: searchMethod,
//        resultsRelevancyThreshold: querySettings.resultsRelevancyThreshold,
//        lambda: querySettings.lambdaValue,
//        temperature: querySettings.llmTemperature,
      };

//      if (appData?.data?.results?.config?.temperature !== undefined) {
//        requestData.temperature = appData.data.results.config.temperature;
//      }

      let requestFilters = filters
        .map((filter) => [`doc.${filter[0]}`, filter[1], filter[2]])
        .filter(
          (filter) =>
            filter[2] !== null && filter[2] !== "" && filter[2].length > 0
        );
      if (selectedMetadataFilter.key && selectedMetadataFilter.value) {
        requestFilters.push([
          `doc.${selectedMetadataFilter.key}`,
          selectedMetadataFilter.operator,
          selectedMetadataFilter.value,
        ]);
      }
      requestFilters = requestFilters.filter(
        (filter) => filter[2] !== null && filter[2] !== ""
      );

      if (requestFilters.length > 0) {
        requestData.filters = requestFilters;
      }

      try {
        const response = await axios({
          method: "POST",
          url: `/api/v1/web/search`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: requestData,
        });

        return response.data;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert("A server error occurred. Please try again later.");
        } else {
          alert("An error occurred. Please try again later.");
        }
        throw error;
      }
    };

    const fetchRelatedQuestions = async () => {
      let requestData = {
        corpusId: parseInt(selectedCorpusId),
        query: query,
      };

      try {
        const response = await axios({
          method: "POST",
          url: `/api/v1/web/search/relatedQuestions`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: requestData,
        });

        const relatedQuestions = response.data.results.map(
          (suggestedQuestionObject) => ({
            question: suggestedQuestionObject.question,
            similarityScore: suggestedQuestionObject.similarityScore,
          })
        );

        if (language !== "English") {
          const questionTranslationPromises = relatedQuestions.map(
            async (suggestedQuestionObject, index) => {
              const translatedQuestion = await translateText(
                suggestedQuestionObject.question
              );
              return {
                ...suggestedQuestionObject,
                question: translatedQuestion,
                index: index,
              };
            }
          );
          const translatedQuestions = await Promise.all(
            questionTranslationPromises
          );
          translatedQuestions.sort((a, b) => a.index - b.index);
          setSuggestedQuestions(
            translatedQuestions.map((item) => ({
              question: item.question,
              similarityScore: item.similarityScore,
            }))
          );
        } else {
          setSuggestedQuestions(relatedQuestions);
        }
      } catch (error) {
        const suggestedSampleQuestions = selectedCorpus.sampleQuestions.map(
          (question) => ({
            question,
            similarityScore: null,
          })
        );
        setSuggestedQuestions(suggestedSampleQuestions);
      }
    };

    const saveRequestLogId = async (requestLogIdValue) => {
      setRequestLogId(requestLogIdValue);
    };

    try {
      let responseData = await submitSearchQuery();
      await fetchRelatedQuestions();

      if (responseData && responseData.results) {
        await saveRequestLogId(responseData.results.requestLogId);
      }

      /*
      if (responseData && responseData.results && responseData.results.results) {
        const relevantResponses = responseData.results.results.filter(res => res.score > querySettings.resultsRelevancyThreshold);

        if ((relevantResponses.length === 0) || (responseData.results.summary == "The returned search results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.") || (responseData.results.summary == "The returned results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.")) {
          if (selectedCorpus.fallbackToLlmResponse) {
            const answer = await submitFallbackQuery(query, responseData.results.requestLogId);
            setSummary(answer);
            setResponseList([]);
            setDocumentList([]);
            setSummaryRedirect(false);
            setSummaryIsFallback(true);
            return null;
          } else {
            setSummary("The returned results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.");
            setSummaryRedirect(true);
            setResponseList([]);
            setDocumentList([]);
            return null;
          }
        } else if (relevantResponses.length < selectedCorpus.numberOfResultsToSummarize) {
          responseData = await submitSearchQuery(relevantResponses.length);
          if ((responseData.results.summary == "The returned search results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.") || (responseData.results.summary == "The returned results did not contain sufficient information to be summarized into a useful answer for your query. Please try a different search or restate your query differently.")) {
            const answer = await submitFallbackQuery(query);
            setSummary(answer);
            setSummaryRedirect(false);
            return null;
          }
        }
      }
      */

      if (
        responseData &&
        responseData.results &&
        responseData.results.summary
      ) {
        let translatedSummary;
        if (language !== "English") {
          translatedSummary = await translateSummary(
            responseData.results.summary,
            language,
            responseData.results.requestLogId
          );
        } else {
          translatedSummary = responseData.results.summary;
        }
        setSummaryIsFallback(false);
        setSummary(translatedSummary);
        setPersonaSummary("");
        setReformattedSummary("");
        setResponseList([]);
        const translatedResults =
          language !== "English"
            ? await Promise.all(
                responseData.results.results.map(async (res) => ({
                  ...res,
                  text: await translateSummary(
                    res.text,
                    language,
                    responseData.results.requestLogId
                  ),
                }))
              )
            : responseData.results.results;
        setSummaryRedirect(false);
        if(responseData.results.resultType === "llmQuery") setSummaryIsFallback(true)
        if(responseData.results.resultType === "fallback") setSummaryRedirect(true)
        const extractedResponses = translatedResults.map((res, index) => {
          const metaTitle = res.content.metadata.find(
            (meta) => meta.name === "title"
          );
          const title = metaTitle ? metaTitle.value : "";
          return {
            text: res.text,
            score: res.score,
            documentIndex: index,
            title: title,
            id: res.content.id,
            metadata: res.content.metadata,
            displayContent: res.displayContent,
            summary: res.summary
          };
        });
        setResponseList(extractedResponses);

        const documentInfo = responseData.results.results.map((doc) => {
          const metadata = doc.content.metadata.reduce((acc, meta) => {
            acc[meta.name] = meta.value;
            return acc;
          }, {});

          return {
            id: doc.content.id,
            metadata: metadata,
          };
        });
        setDocumentList(documentInfo);

        if (persona && persona !== "") {
          const newPersonaSummary = await personaRewrite(
            responseData.results.summary,
            persona,
            language,
            responseData.results.requestLogId,
            selectedCorpusId
          );
          setPersonaSummary(newPersonaSummary);
        } else {
          setPersonaSummary("");
        }

        if (formatInstructions && formatInstructions !== "") {
          const newReformattedSummary = await reformatSummary(
            responseData.results.summary,
            formatInstructions,
            language,
            responseData.results.requestLogId
          );
          setReformattedSummary(newReformattedSummary);
        } else {
          setReformattedSummary("");
        }

        setNewQuerySubmitted(true);
      }
    } catch (error) {
      console.error("Query error:", error);
      setIsLoading(false);
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  // const { sendMessage, activeMessage, messageHistory, isStreamingResponse, hasError, startNewConversation } =
  // useChat({
  //   customerId: "CUSTOMER_ID",
  //   corpusIds: ["CORPUS_ID_1", "CORPUS_ID_2", "CORPUS_ID_N"],
  //   apiKey: "API_KEY",
  //   enableStreaming: true, // Enable streaming, false otherwise. Defaults to true.
  //   language: "fra" // Response language. Defaults to "eng" for English. See our types for more information.
  // });

  return (
    <SummaryContext.Provider
      value={{
        appData,
        setAppData,
        suggestedQuestions,
        setSuggestedQuestions,
        summary,
        setSummary,
        summaryRedirect,
        setSummaryRedirect,
        responseList,
        setResponseList,
        documentList,
        setDocumentList,
        selectedCorpusId,
        highlightedIndex,
        setHighlightedIndex,
        selectedDocument,
        setSelectedDocument,
        selectedDocumentUrl,
        setSelectedDocumentUrl,
        selectedMetadataFilter,
        setSelectedMetadataFilter,
        sourceUrl,
        setSourceUrl,
        sourceMediaType,
        setSourceMediaType,
        newQuerySubmitted,
        setNewQuerySubmitted,
        audioEnabled,
        setAudioEnabled,
        language,
        setLanguage,
        currentLanguage,
        setCurrentLanguage,
        translationUpdated,
        setTranslationUpdated,
        citeSource,
        setCiteSource,
        isReadyToSubmit,
        setIsReadyToSubmit,
        handleSubmit,
        persona,
        setPersona,
        personaSummary,
        setPersonaSummary,
        formatInstructions,
        setFormatInstructions,
        reformattedSummary,
        setReformattedSummary,
        summaryIsFallback,
        setSummaryIsFallback,
        searchMethod,
        setSearchMethod,
        filterTagOptions,
        setFilterTagOptions,
        filters,
        setFilters,
        emptyFilters,
        setEmptyFilters,
        requestLogId,
        setRequestLogId,
        fallbackMessage,
        setFallbackMessage,
        clearFiltersTrigger,
        setClearFiltersTrigger,
      }}
    >
      <CssBaseline />
      {preloader ? (
          <CircularProgress />
        ) : (
      <Box className={classes.root} style={{ display: contentReady ? 'flex' : 'none' }}>
        <Box
          className={`${classes.sidebar} ${sidebarScroll ? "scrollable" : ""}`}
          onMouseEnter={() => setSidebarScroll(true)}
          onMouseLeave={() => setSidebarScroll(false)}
        >
          <List>
            <ListItem>
              <Typography variant="h6">Knowledge Base</Typography>
            </ListItem>
            <ListItem>
              {appData && (
                <CorpusSelector
                  setCorpusId={setSelectedCorpusId}
                  environmentVariables={process.env}
                  resetInterface={resetInterface}
                  inputPassword={inputPassword}
                />
              )}
            </ListItem>

            <Divider sx={{ bgcolor: "#888888", borderBottomWidth: 2 }} />

            <ListItem>
              <Typography variant="h6">Settings</Typography>
            </ListItem>

            {appData && (
              <>
                <ListItem>
                  <SummaryPersona />
                </ListItem>
                <ListItem>
                  <SummaryTranslator />
                </ListItem>
              </>
            )}

            <ListItem>
              <Box sx={{width: "100%"}}>
                <FormControl fullWidth>
                  <InputLabel>
                    Search Method
                  </InputLabel>
                  <Select value={searchMethod} label="Search Method" onChange={updateSemanticSearchStrength}>
                    <MenuItem key="Keyword" value="keyword">
                      Keyword
                    </MenuItem>
                    <MenuItem key="Hybrid" value="hybrid">
                      Hybrid
                    </MenuItem>
                    <MenuItem key="Semantic" value="semantic">
                      Semantic
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </ListItem>

            <ListItem>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={audioEnabled}
                        className="checkbox"
                        onChange={(e) => setAudioEnabled(e.target.checked)}
                      />
                    }
                    label="Audio"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={citeSource}
                        className="checkbox"
                        onChange={(e) => setCiteSource(e.target.checked)}
                      />
                    }
                    label="Citations"
                  />
                </Grid>
              </Grid>
            </ListItem>

            {showFilters && filterTagOptions && (
              <>
                <Divider sx={{bgcolor: "#888888", borderBottomWidth: 2}} />
                <ListItem>
                  <Typography variant="h6">
                    Filters
                  </Typography>
                </ListItem>
                {filterTagOptions &&
                  filterTagOptions.map((option, index) => (
                    <ListItem key={String(index)}>
                      <Box key={String(index)} width="100%" >
                        <FilterTag
                          key={String(index)}
                          filterTagOption={option}
                        />
                      </Box>
                    </ListItem>
                  ))}
                <ListItem>
                  <Box
                    sx={{display: "flex", alignItems: "center"}}
                  >
                    <Button
                      fullWidth
                      className="button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setFilters(emptyFilters);
                        setClearFiltersTrigger((prev) => !prev);
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Box>
                </ListItem>
              </>
            )}
          </List>
        </Box>

        <Divider
          flexItem
          orientation="vertical"
          height="100%"
          sx={{ bgcolor: "#888888", borderWidth: 1 }}
        />
          <Box
            className={`${classes.content} ${
              mainbodyScroll ? "scrollable" : ""
            }`}
            onMouseEnter={() => setmainbodyScroll(true)}
            onMouseLeave={() => setmainbodyScroll(false)}
            sx={{ minWidth: "1000px", minHeight:'100vh' }}
          >
            <Grid item xs={12}>
              <Box sx={{ padding: 2, marginBottom: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={10} sm={10} md={11} lg={11}>
                    <TextField
                      fullWidth
                      label="Ask a question"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      onKeyPress={handleKeyPress}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={2} sm={2} md={1} lg={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      className="button"
                      onClick={handleButtonClick}
                      disabled={!selectedCorpusId || !query || isLoading}
                    >
                      {isLoading ? (
                        <FaSpinner className={classes.spinner} size={24} />
                      ) : (
                        "Go"
                      )}
                    </Button>
                  </Grid>
                </Grid>
               </Box>
            </Grid>
            <Grid item xs={12}>
              {selectedMetadataFilter.key &&
                selectedMetadataFilter.operator &&
                selectedMetadataFilter.value && (
                  <Grid item xs={12}>
                    <SourceFilter />
                  </Grid>
                )}

              <Grid item xs={12}>
                {suggestedQuestions && (
                  <SuggestedQuestions
                    handleSampleQuestion={handleSampleQuestion}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {summary && <SummaryDisplay />}
                {summary && (
                  <Divider sx={{bgcolor: "#888888", borderBottomWidth: 5}} />
                )}
              </Grid>

              <Grid item xs={12}>
                {responseList.length > 0 && (
                  <ResponseDisplay responseList={responseList} />
                )}
              </Grid>
              {/* <VectaraChatbot/> */}
              {/* <Vchatbot/> */}
              {/* <ReactChatbot
  customerId="CUSTOMER_ID"
  corpusIds={["CORPUS_ID_1", "CORPUS_ID_2", "CORPUS_ID_N"]}
  apiKey="API_KEY"
  title="My Chatbot"
  placeholder="Chat with your AI assistant"
  inputSize="large"
  // emptyStateDisplay={<MyEmptyStateDisplayComponent />}
  isInitiallyOpen={false}
  zIndex={1}
  enableStreaming={true}
  language="fra"
/> */}
            </Grid>

            {/* {summary && (
              <Box className={classes.footerButtonContainer}>
                <Button
                  className="button"
                  onClick={clickShareButton}
                  variant="contained"
                  color="primary"
                  startIcon={<ShareIcon />}
                >
                  Share
                </Button>
                <Button
                  className="button"
                  onClick={clickSaveSearchButton}
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                >
                  Save Search
                </Button>

              </Box>
            )} */}
          </Box>

        <Snackbar
          open={shareButton}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Search shared."
        />
        <Snackbar
          open={saveSearchButton}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          message="Search saved."
        />

      </Box>
        )}
    </SummaryContext.Provider>
  );
}

export default Interface;
