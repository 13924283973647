import React, { useState } from "react";
import Header from "./Header";
import Interface from "./Interface";
import MyProfile from "./MyProfile";
import Administration from "./Administration";
import ApiDoc from "./ApiDoc";
import Footer from "./Footer";
import { TextField, Button, Box, Container } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import "./DefaultStyle.css";

const PasswordPage = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState({});
  const [subscriptionStartDate, setSubscriptionStartDate] = useState("");
  const [displayComponent, setDisplayComponent] = useState("Interface");
  const [clientLogoUrl, setClientLogoUrl] = useState("");
  // const [preloader, setPreloader] = useState(false);

  const handleEmailAddressChange = (e) => {
    setEmailAddress(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      verifyPassword();
    }
  };

  const verifyPassword = () => {
    if (!emailAddress || !inputPassword) {
      alert("Please enter both an email address and a password.");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailAddress)) {
      alert("Please enter a valid email address.");
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const appCode = searchParams.get("appCode") || "";

    if (!appCode) {
      alert(
        "Your login URL is not valid. Please contact your administrator with the following error message:\n\nERROR_INVALID_APPCODE"
      );
      return;
    }

    // setPreloader(true);

    const fetchUserData = axios
      .post(`/api/v1/web/auth/login`, {
        email: emailAddress,
        password: inputPassword,
        appCode: appCode,
      })
      .then((response) => {
        setUserData(response.data.results.user);
        setIsAuthenticated(true);
      })
      .catch((e) => {
        if (e && e.response && e.response.status && e.response.status === 500) {
          alert(
            "Error connecting to server. Please contact your administrator."
          );
        } else if (
          e.response &&
          e.response.request &&
          e.response.request.response &&
          JSON.parse(e.response.request.response).message === "Invalid appCode"
        ) {
          alert(
            "Your login URL is not valid. Please contact your administrator with the following error message:\n\nERROR_INVALID_APPCODE"
          );
          return;
        } else if (
          e.response &&
          e.response.status >= 400 &&
          e.response.status < 500
        ) {
          const errorMessage =
            e.response.data && e.response.data.message
              ? e.response.data.message
              : "Unknown error.";
          alert(`Error ${e.response.status}: ${errorMessage}`);
        } else {
          alert("Error logging in. Please try again.");
        }
        // setPreloader(false);
        return;
      });

    // const delay = new Promise((resolve) => setTimeout(resolve, 3000));

    // Promise.all([fetchUserData, delay]).then(() => {
    //   setPreloader(false);
    // });
  };

  return (
    <Box sx={{ width: "100%", margin: "0", padding: "0" }}>
      <Header
        setEmailAddress={setEmailAddress}
        setInputPassword={setInputPassword}
        isAuthenticated={isAuthenticated}
        setIsAuthenticated={setIsAuthenticated}
        setDisplayComponent={setDisplayComponent}
        userRole={userData.role}
        clientLogoUrl={clientLogoUrl}
      />
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!isAuthenticated ? (
          <Container
            component="main"
            maxWidth="xs"
            sx={{
              marginTop: 8,
              marginBottom: 8,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                padding: "2em",
                border: "2px solid #64aced",
                borderRadius: "5px",
                transition: "all .2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.02)",
                  boxShadow: " 0 3px 20px rgb(0 0 0 / 0.2)",
                },
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email Address"
                id="email"
                value={emailAddress}
                onChange={handleEmailAddressChange}
                onKeyPress={handleKeyPress}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={inputPassword}
                onChange={handlePasswordChange}
                onKeyPress={handleKeyPress}
                variant="outlined"
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: 3, marginBottom: 2 }}
                onClick={verifyPassword}
              >
                Submit
              </Button>
            </Box>
          </Container>
        ) : displayComponent === "Interface" ? (
          <Interface
            inputPassword={inputPassword}
            userData={userData}
            setSubscriptionStartDate={setSubscriptionStartDate}
            setIsAuthenticated={setIsAuthenticated}
            setEmailAddress={setEmailAddress}
            setInputPassword={setInputPassword}
            setClientLogoUrl={setClientLogoUrl}
          />
        ) : displayComponent === "MyProfile" ? (
          <MyProfile userData={userData} />
        ) : displayComponent === "Administration" &&
          userData.role === "admin" ? (
          <Administration subscriptionStartDate={subscriptionStartDate} />
        ) : displayComponent === "ApiDoc" &&
          userData.role === "admin" ? (
          <ApiDoc />
        ) : null}
      </Box>
      <Footer />
    </Box>
  );
};

export default PasswordPage;
